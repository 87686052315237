import React from "react";
import styled from "styled-components";
import { DotWave, Jelly } from '@uiball/loaders';
import '../App.css';

const Page = styled.div`
    height: ${window.innerHeight}px;
    width: 100vw;
    background-color: var(--offBlack);
    display: flex;;
    justify-content: center;
    overflow-y:hidden;
    overflow-x:hidden;
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items:center;
    display: flex;
    flex-direction: column;
`

function Limbo(){

    function handleReconnect(e){
        e.preventDefault()
        window.location.reload();
    }
    return (
        <Page>
            <Content >
                <DotWave 
                size={80}
                speed={1.0} 
                color="#99ffcc" 
                />
                <h1 style={{margin: "3vh"}} className="gamePiecePrompt"> Connection was interrupted</h1>
                <button className="reconnectBtn" onClick={handleReconnect}> Reconnect </button>

            </Content>
        </Page>
    )

}

export default Limbo;