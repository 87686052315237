import React, { useState, useEffect } from 'react';
import styled from 'styled-components';


/*
    Rules page, next
    First place your boat
    OnClick -> Capture tileId as boatSpot

    If boatSpot:
    Display my Icon
    Now pick {numShots} spots to shoot

    onClick -> shooting is true
    add tileId to array of shots
    If shotsLeft = 0
    
    emit( input submitted, [boatSpot: 11, shots:[1,2,3]])
    on input results
    ifDisplaying:
    For each player
        show their shot explosion emoji, 
        pause
        if sinkingSomeone, display dead emoji with icons and red border
        pause

    If myBoatSpot( hit:true), sunk = true
    Message, You were sunk! Take a drink
*/

const iconPaths = [
    "bee4x.png",
    "bull4x.png",
    "robot4x.png",
    "eagle4x.png",
    "snake4x.png",
    "cowboy4x.png",
    "worm4x.png",
    "queen4x.png",
    "forklift4x.png",
    "octopus4x.png",
    "lime4x.png",
    "eggplant4x.png",
    "portrait4x.png"
];

const Page = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
`;

const Ocean = styled.div`
height: 60vh;
width: 100vw;
display: grid;
justify-content: center;
grid-auto-rows: 20%;
grid-auto-columns: 15%;
grid-gap: 3%;
grid-template-areas:
"b0 b1 b2 b3"
"b4 b5 b6 b7"
"b8 b9 b10 b11"
"b12 b13 b14 b15";
/* hide the scrollbar */
::-webkit-scrollbar {
    display: none;
  }
`
/*
2 players, shots = 5
3,4 players shots = 4
5,6 players shots = 3
7,8,9 players shots = 2
10,11,12 shots - 1
*/

const MineButton = styled.div`
align-items: center;
justify-content: center;
display: flex;
border: 2px solid white;
`

function ShipsChallenge(props) {
    const [myBoat, setMyBoat] = useState(null);
    const [myShots, setMyShots] = useState([]);
    const [shotsLeft, setShotsLeft] = useState(null);
    const [imSunk, setImSunk] = useState(false);
    const [sunkShips, setSunkShips] = useState([]);
    const [curShots, setCurShots] = useState([])
    const [revealing, setRevealing] = useState(false);
    const [done, setDone] = useState(false);


    useEffect(()=>{
       const activePlayers =  props.players.filter((player)=>player.connected && !player.waiting)
       const numPlayers = activePlayers.length;
       if([1,2].includes(numPlayers)){
        setShotsLeft(5)
       } else if([3,4].includes(numPlayers)){
        setShotsLeft(4)
       } else if([5,6].includes(numPlayers)){
        setShotsLeft(3)
       } else if([7,8,9].includes(numPlayers)){
        setShotsLeft(2)
       } else {
        setShotsLeft(1)
       }
    },[])

    useEffect(()=>{
        console.log(props.socketData)
        if(props.socketData.hasOwnProperty("results")){
            setRevealing(true);
            showShots()
        }
    },[props.socketData])
  

function handleBoatPlaced(e){
    e.preventDefault()
    const boatLoc = e.currentTarget.id;
    setMyBoat(boatLoc) 
}

 
async function showShots(){
    let shipLocations = {}
    const res = props.socketData.results;
    Object.keys(res).forEach((key)=>{
        if(shipLocations.hasOwnProperty(res[key].boatLoc)){
            shipLocations[res[key].boatLoc].push(res[key].iconId)
        } else {
            shipLocations[res[key].boatLoc] = [res[key].iconId]
        }
    })

    for(let i =0; i<Object.keys(res).length; i++){
        const key = Object.keys(res)[i];
        setCurShots(res[key].shots)
        setTimeout(() => {
            setCurShots(null);
        }, 1000);

        res[key].shots.forEach((shot)=>{
            if(shipLocations[shot]){
                shipLocations[shot].forEach((sunkShip) =>{
                    setSunkShips(prevSunks => [...prevSunks,{
                        "icon":sunkShip,
                        "loc":shot
                    }]);
                })

            }
            if(myBoat === shot){
                setImSunk(true);
            }
        })
        await new Promise((resolve) => setTimeout(resolve, 2000));
    }
    setRevealing(false)
    setDone(true)
}

function handleShotPlaced(e){
    e.preventDefault()
    const shotLoc = e.currentTarget.id;
    if(shotLoc in myShots){
        return
    }
    setCurShots(prevTargets => [...prevTargets, shotLoc])
    if(shotsLeft===1){
        //If you placed your last shot, send in the submission
        let payload = {};
        let myIcon = props.players.find(u => u.uid === props.yourId).iconId
        payload['userInput'] = {
            "boatLoc": myBoat,
            "shots": [...myShots, shotLoc],
            "iconId" : myIcon
        };
        payload['type'] = "input submitted"
        props.sendEvent("input submitted", payload)  
        setCurShots([])
    }
    //Update state either way

    setMyShots(prevShots => [...prevShots, shotLoc]);
    setShotsLeft(prevShotNum => prevShotNum - 1)
}

function getTopPrompt(){
    if(!myBoat){
        return "Place your boat"
    }
    if(myBoat && shotsLeft){
        return `Pick ${shotsLeft} spots to shoot`
    }
    if(myBoat && !revealing && !done){
        return "Waiting..."
    }
    if(revealing && !done){
        return "Shooting..."
    }
    if(done && imSunk){
        return "You got sunk! Take a drink"
    }
    if(done && !imSunk){
        return "You lived!"
    }
}
function renderTiles(){
    let tiles = []
    if(!myBoat){
        for(let i=0;i<16;i++){
            tiles.push(<div data-tile={`b${i}`}
             style={{gridArea: `b${i}`, border: "1px solid #99ccff"}} 
            id={`b${i}`}
            onClick={handleBoatPlaced} />)
        }
    } else {
        for(let i=0;i<16;i++){
            let isSunk = false;
            sunkShips.forEach((ship)=>{
                if(ship.loc===`b${i}`){
                    isSunk = true;
                }
            })
            tiles.push(<div data-tile={`b${i}`} 
            style={{gridArea: `b${i}`, border: `1px solid ${isSunk?"#FF3333":"#99ccff"}`}} 
            id={`b${i}`}
            onClick={shotsLeft?handleShotPlaced:console.log()} />)
        }
    }
    return tiles
}

function renderMyBoat(){
    if(myBoat && !revealing &&!imSunk){
        return (<div style={{heigth: "100%", alignContent:"center", fontSize: "5vh",gridArea: `${myBoat}`}} 
        id={`b${myBoat}`}>⛵</div>)
    }
}

function renderSunkShips(){
    let sunks = [];
    sunkShips.forEach((ship)=>{
        const tile = document.querySelector(`[data-tile="${ship.loc}"]`);
        const { top, left, width, height } = tile.getBoundingClientRect();
        sunks.push(<img src={`/icons/${iconPaths[parseInt(ship.icon)]}`}
        key={ship.icon}
        style = {{ gridArea:`${ship.loc}`,
        width: "100px",
        height: "100px",
        position: "absolute",
        top: top-50, // position in the center of the tile
        left: left-20,}}/>)
    })
    return sunks;
}

function renderCurShot(){
    if(curShots){
        let shots = []
        curShots.forEach((shot)=>{
            shots.push(<div style={{fontSize: "5vh", gridArea: `${shot}`}}>💥</div>)
        })
        return shots
    }
    return null
}

  function renderButton(){
    if(props.yourId===props.turnPlayer && done){
        return <button className='splashNavBtn' onClick={props.handleClose}>END TURN</button>
     }
     return <button className='splashNavBtn' style={{opacity: 0}}></button>
  }

  return (
    <Page className="challengeContainer"
    style={{ transform: props.flipped ? 'rotateY(180deg)' : '' }}>
      <div className='rulesTitleContainer' style={{fontFamily: "var(--titleFont)", textShadow: 'none', borderBottom: "none"}}>
        {getTopPrompt()}
      </div>
        <Ocean>
        {renderTiles()}
        {renderMyBoat()}
        {renderSunkShips()}
        {renderCurShot()}
        </Ocean>
      {renderButton()}
    </Page>
  );
}

export default ShipsChallenge;
