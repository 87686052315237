import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Odometer from '../Odometer';

const Page = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
`;

const MineGrid = styled.div`
height: 50vh;
width: 100%;
display: grid;
justify-content: center;
grid-auto-rows: 20%;
grid-auto-columns: 20%;
grid-gap: 3%;
grid-template-areas:
"a0 a1 a2 a3"
"a4 a5 a6 a7"
"a8 a9 a10 a11"
"a12 a13 a14 a15";


/* hide the scrollbar */
::-webkit-scrollbar {
    display: none;
  }

`

const MineButton = styled.div`
align-items: center;
justify-content: center;
display: flex;
border: 4px solid white;
`

function MinesChallenge(props) {
  const [gridStatus, setGridStatus] = useState(null);
  const [numWines, setNumWines] = useState(0)
  const [cashedOut, setCashedOut] = useState(false)
  const [busted, setBusted] = useState(false)
  const [activeBoard, setActiveBoard] = useState(true)

  useEffect(() => {
    let newArray = [
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false},
        {"showing": false, "wine": true, "picked": false}
    ];
    props.bombLocations.forEach((bombIndex)=>{
        console.log(bombIndex)
        newArray[bombIndex].wine = false;
    })

    setGridStatus(newArray)
  }, []);

  useEffect(() => {
    if (props.socketData.userInput) {
        if(props.socketData.userInput==="cashed"){
            gameOver(true)
        } else {
            const pickedIndex = parseInt(props.socketData.userInput.substring(4))
            let pickedMine = gridStatus[pickedIndex]
            pickedMine.picked = true;
            pickedMine.showing = true;
            if(pickedMine.wine){
                setNumWines(numWines+1)
            } else {
                setNumWines(numWines+1)
                gameOver(false)
            }
        }
    }
  }, [props.socketData]);


  function gameOver(cashed){
    gridStatus.forEach((mine)=>{
        mine.showing = true;
    })
    if(cashed){
        setCashedOut(true)
    } else {
        setBusted(true)
    }
  }
  
function mineFlipLocal(payload){
  const pickedIndex = parseInt(payload.userInput.substring(4))
  let pickedMine = gridStatus[pickedIndex]
  pickedMine.picked = true;
  pickedMine.showing = true;
  if(pickedMine.wine){
      setNumWines(numWines+1)
  } else {
      setNumWines(numWines+1)
      gameOver(false)
  }
}

function handleMineClicked(e){
    e.preventDefault();
    setActiveBoard(false);
    setTimeout(()=>{
      setActiveBoard(true)
    },1000)
    let mineId = e.currentTarget.id
    let mineNum = parseInt(mineId.substring(4))
    if(gridStatus[mineNum].showing){
        return
    }
    let payload = {};
    payload['userInput'] = mineId;
    payload['type'] = "chal update"
    props.sendEvent("chal action", payload)
    //mineFlipLocal(payload)
    
}

function handleCashOut(e){
    e.preventDefault()
    let payload = {};
    payload['userInput'] = "cashed";
    payload['type'] = "chal update"
    props.sendEvent("chal action", payload)
}

function getTopPrompt(){
    if(cashedOut){
        return "Cash out! "+props.turnPlayerName+" gives "+numWines+" drinks"
    } 
    else if (busted){
        return "Bomb! "+props.turnPlayerName+" takes "+numWines+" drinks"
    } else {
        return `Drinks: ${numWines}`
    }
}

function getTopContainer(){
  if(cashedOut || busted){
    return(
    <div className='minesTitleContainer' style={{textShadow: 'none', borderBottom: "none"}}>
      {getTopPrompt()}
    </div>
    )
  } 
  return(
    <div className ='minesCounterContainer'>
      <div className='minesCounterTitle'>
        Drinks:
      </div>
      <Odometer number={numWines} />
    </div>
  )
//     

}

function getBottomPrompt(){
    return `Avoid 2 💣`
}

  function renderButton(){

    if(props.yourId===props.turnPlayer){
        if(cashedOut || busted){
            return <button className='splashNavBtn' onClick={props.handleClose}>END TURN</button>
        }
        if(numWines>0){
            return <button className='splashNavBtn' style={{whiteSpace: "pre-line", width:"80%"}} onClick={handleCashOut}>{`Click to Cash Out \n& Give ${numWines} Drink${numWines>1?'s':''}`}</button>
        }
     }
     return <button className='splashNavBtn' style={{opacity: 0}}></button>
  }

  function renderMines(){
    let mines = [];
    if(!gridStatus){
        return null
    }
    for(let i=0;i<16;i++){
            mines.push(
                <div class= {'mineFlipCard'+ (gridStatus[i].showing?' flipped':'')}
                id = {"mine"+i}
                style={{gridArea:"a"+i}}
                onClick={props.yourId===props.turnPlayer && activeBoard && !gridStatus[i].picked?handleMineClicked:null}>
                <div class="mineFlipCard-inner">
                  <div class="mineFlipCard-front"
                  style={{gridArea:"a"+i, 
                  backgroundColor: (gridStatus[i].picked && gridStatus[i].wine?"var(--baseColor)":((cashedOut || busted)?"red":"white")),
                  opacity: (activeBoard?1:.8)}}>
                          <img src="/icons/portrait4x.png" alt="logo" class="mineLogo"/>
                  </div>
                  <div class="mineFlipCard-back"
                  style={{gridArea:"a"+i, backgroundColor: (gridStatus[i].picked && gridStatus[i].wine?"var(--baseColor)":((cashedOut || busted)?"red":"white"))}}>
                    {gridStatus[i].wine?"🍺":"💣"}
                  </div>
                </div>
              </div>
            )
    } 
    return mines
  }

  return (
    <Page className="challengeContainer"
    style={{ transform: props.flipped ? 'rotateY(180deg)' : '' }}>
        {getTopContainer()}
        <MineGrid>
        {renderMines()}
        </MineGrid>
        <div className='mineSubtitleContainer' style={{textShadow: 'none', borderBottom: "none"}}>
        {getBottomPrompt()}
      </div>
      {renderButton()}
    </Page>
  );
}

export default MinesChallenge;
