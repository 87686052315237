import React, { useState, useEffect } from 'react';
import styled from 'styled-components';


const Page = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;
`;

const iconPaths = [
    "game piece-0.png",
    "game piece-01.png",
    "game piece-02.png",
    "game piece-03.png",
    "game piece-04.png",
    "game piece-05.png",
    "game piece-06.png",
    "game piece-07.png",
    "game piece-08.png",
    "game piece-09.png",
    "game piece-10.png",
    "game piece-11.png",
    "portrait4x.png"
];

const template = {
    "prompts":[
        ""
    ],
    "promptAssignments":{
        "uuid": 0,
        "uuid": 2,
        "uuid": 0
    }
}

const TypingText = ({ text, speed }) => {
    const [displayedText, setDisplayedText] = useState('');
  
    useEffect(() => {
      let index = 0;
  
      const intervalId = setInterval(() => {
        if (index < text.length) {
          setDisplayedText((prevText) => prevText + text.charAt(index));
          index += 1;
        } else {
          clearInterval(intervalId);
        }
      }, speed);
  
      return () => clearInterval(intervalId);
    }, [text, speed]);
  
    return displayedText;
  };

function QuipChallenge(props) {
    const [countdown, setCountdown] = useState(0);
    const [collectingPrompt, setCollectingPrompt] = useState(true);
    const [waiting, setWaiting] = useState(false);
    const [voting, setVoting] = useState(false);
    const [displayingResults, setDisplayingResults] = useState(false);
    const [myResponse, setMyResponse] = useState("")
    const [countdownStarted, setCountdownStarted] = useState(false)
    const [displayOption1, setDisplayOption1] = useState(false)
    const [displayOption2, setDisplayOption2] = useState(false)
    const [playerResponses, setPlayerResponses] = useState({})
    const[delaySet, setDelaySet] = useState(false)
    const[myVote, setMyVote] = useState(-1)
    const[votes, setVotes] = useState({})
    const[votesRevealed, setVotesRevealed] = useState(false)
    const[curSet, setCurSet] = useState(0);


    useEffect(() => {
        if (props.socketData && props.socketData.hasOwnProperty("type")){

 
            if(props.socketData.type === "input results" && Object.keys(playerResponses).length === 0){
                setPlayerResponses(props.socketData.results)
                clearStates()
                setVoting(true)
            }
            if(props.socketData.type === "vote results"){
               setVotes(props.socketData.results)
               setVoting(false)
               clearStates()
               setDisplayingResults(true);
            }
        }
      }, [props.socketData]);

    useEffect(() => {
        let interval;
        if (countdown > 0) {
            interval = setInterval(() => {
                setCountdown(countdown => countdown - 1);
            }, 1000);
        }
        return () => clearInterval(interval); // Cleanup the interval on component unmount or countdown reset
    }, [countdown]);

    function clearStates(){
        setDisplayOption1(false)
        setDisplayOption2(false)
        setDelaySet(false);
        setMyVote(-1);
        setWaiting(false);
        setVotesRevealed(false);
        setCollectingPrompt(false);
    }

    function handleQuipSubmit(e){
        e.preventDefault();
        setCountdown(0);
    }

    function sendMyResponse(){
        let payload = {};
        payload['userInput'] = myResponse.length>0?myResponse:"[No Response]";
        payload['type'] = "quip submitted"
        props.sendEvent("quip submitted", payload)   
    }

    function sendMyVote(){
        let payload = {};
        const myIcon = props.players.find(u => u.uid === props.yourId).iconId

        payload['voteId'] = myVote;
        payload['iconId'] = myIcon
        payload['voteType'] = "quip vote"
        props.sendEvent("quip vote", payload)   
    }

    function updateMyVote(e){
        e.preventDefault()
        if(countdown){
            setMyVote(e.target.id)
            setCountdown(0)
        }
    }

    function handleResponseChange(e){
        setMyResponse(e.target.value.slice(0, 45).replace(/[^a-zA-Z0-9\s]/g, ''));
    }

    function getMyName(){
        return props.players.find(u => u.uid === props.yourId).name
    }

    function getMyPromptId(){
        for(let i = 0; i<Object.keys(props.varData['promptAssignments']).length; i++){
            if(props.varData['promptAssignments'][i]['player1'] === props.yourId ||
            props.varData['promptAssignments'][i]['player2'] === props.yourId){
                return i
            }
        }
    }

    function renderVoteIcons(voteOption){
        const icons = []
        const iconHeight = (votes[voteOption].length > 5? "30%" :"50%")
        for(let i = 0; i < votes[voteOption].length; i++){
            icons.push(
                <img src={`/icons/${iconPaths[parseInt(votes[voteOption][i])]}`}
                style = {{opacity: 0, width: "auto", height: iconHeight, animation: `reveal .2s linear ${1+(i*.2)}s 1 forwards`}}/>
            )
        }
        return icons
    }

    function getWinnerId(){
        const player1Votes = votes["1"].length;
        const player2Votes = votes["2"].length;
        if(player1Votes === player2Votes){
            return -1;
        }
        if(player1Votes > player2Votes){
            return '1'
        }
        return '2'
    }

    function getResponse(index){

        const playerId = props.varData['promptAssignments'][curSet]['player'+index]
        if(playerId==='bot'){
            return props.varData['promptList'][curSet][1]
        }
        return playerResponses[playerId]
    }

    function nextSet(){
        clearStates()
        delete props.varData.promptAssignments[curSet]
        props.varData.promptList.shift()
        setCurSet(curSet=>curSet+1)

        if(Object.keys(props.varData['promptAssignments']).length>0){
            setVoting(true)
        } else {
            if(props.turnPlayer === props.yourId){
                props.handleClose()
            }
        }
    }

    function renderPromptCollection(){
        if(!countdownStarted){
            setCountdownStarted(true)
            setCountdown(60)
        }
        if(!countdown && countdownStarted){
            setCollectingPrompt(false);
            setWaiting(true);
            setCountdownStarted(false);
            setCountdown(0)
            sendMyResponse()
        }
        //                    
        return <>
            <div className="quipTitle">
                {`${getMyName()}, finish the prompt:\n${countdown}`}
            </div>
            <div className="quipPrompt">
                "{props.varData.promptList[getMyPromptId()][0]}"
            </div>

            <input
                    id="quipInput"
                    placeholder="Enter something fun..."
                    type="text"
                    value={myResponse}
                    onChange={handleResponseChange}
                    className="quipInput"
                    onFocus={window.scrollTo(0, 0)}
                    rows={2}
                />
            <button className="quipSendBtn" onClick={handleQuipSubmit}>
                SEND
            </button>
            <div className="quipSubtitle">
                Your answer will go head to head against another player's
            </div>

        
        </>
    }

    function renderVote(){
        const speed = 50;
        const curPrompt = props.varData.promptList[0][0];

        if(!countdown && countdownStarted){
            setCountdownStarted(false)
            setCountdown(0)
            sendMyVote()
        }

        if(!delaySet){
            setDelaySet(true)
            const interval1 = setInterval(() => {
                setDisplayOption1(true)
                clearInterval(interval1)
            }, speed*(curPrompt.length+5));
    
            const interval2 =setInterval(() => {
                setDisplayOption2(true)
                clearInterval(interval2)
                setCountdown(15)
                setCountdownStarted(true)
            }, speed*(curPrompt.length+15));
        }

        return <>
            <div className="quipTitle">
                {`Everyone vote for your favorite\n${countdown?countdown:''}`}
            </div>
            <div className="quipPrompt">
                {!displayOption1 && !displayOption2?
                <TypingText text={curPrompt} speed={speed}/>:
                curPrompt}
            </div>
            {displayOption1?
                <button id='1' className = {`quipVoteButton ${myVote==='1'?'selected':''}`} onClick={updateMyVote}>
                    {getResponse(1)}
                </button>:null}
            {displayOption2?
                <button id='2' className = {`quipVoteButton ${myVote==='2'?'selected':''}`} onClick={updateMyVote}>
                    {getResponse(2)}
                </button>:null}
        </>
    }

    function getResultLabel(playerNum){
        const winner = getWinnerId()
        if(playerNum !== winner){
            if(winner === -1){
                return "- DRINK (Tie)"
            } else {
                return "- DRINK"
            }
        } else {
            let winnerId = getWinnerId()
            winnerId = (winnerId===-1?'1':winnerId)
            const mostVotes = votes[winnerId].length
            return `- won with ${mostVotes} vote${mostVotes>1?'s':''}`
        }
    }

    function renderDisplayingResults(){
        if(!countdown && countdownStarted){
            setCountdownStarted(false)
            setCountdown(0)
            setDisplayingResults(false)
            nextSet()
            return
        }
        if(!delaySet){
            setDelaySet(true)
            let winnerId = getWinnerId()
            winnerId = (winnerId===-1?'1':winnerId)
            const mostVotes = votes[winnerId].length

            const interval = setInterval(() => {
                setVotesRevealed(true)
                clearInterval(interval)
                setCountdown(8)
                setCountdownStarted(true)
            }, 200*(mostVotes+1));
        }

        //Get Ids and Names of players

        if(!props.varData.promptAssignments.hasOwnProperty(curSet)){
            return
        }
        const curPrompt = props.varData.promptList[0][0];
        let player1Id = props.varData.promptAssignments[curSet]['player1']
        const player1Name = (player1Id === "bot"?"🤖 Bot":
        props.players.find(u => u.uid === player1Id).name)

        const player2Id = props.varData.promptAssignments[curSet]['player2']
        const player2Name = (player2Id === "bot"?"🤖 Bot":
        props.players.find(u => u.uid === player2Id).name)

        return <>
            <div className="quipTitle">
                {`Voting Results!\n${countdown?`Skip in ${countdown}`:''}`}
            </div>
            <div className="quipPrompt">
                {curPrompt}
            </div>
            <div className={`quipNameTag ${votesRevealed?(getWinnerId()==='1'?'':'loser'):''}`}>{`${player1Name} ${votesRevealed?getResultLabel('1'):''}`}</div>
            <button id='1' className = {`quipVoteResult ${votesRevealed?(getWinnerId()==='1'?'winner':'loser'):''}`}>
            <div className='quipResultText'>
            {getResponse(1)}
            </div>
            <div className='quipVoteOverlay'>
                {renderVoteIcons(1)}
            </div>
            </button>
 
            <div className={`quipNameTag ${votesRevealed?(getWinnerId()==='2'?'':'loser'):''}`}>{`${player2Name} ${votesRevealed?getResultLabel('2'):''}`}</div>
            <button id='2' className = {`quipVoteResult ${votesRevealed?(getWinnerId()==='2'?'winner':'loser'):''}`}>
            <div className='quipResultText'>
            {getResponse(2)}
            </div>
            <div className='quipVoteOverlay'>
            {renderVoteIcons(2)}
            </div>

            </button>

        </>
    }

    function renderWaiting(){
        return <>
        <div className="quipTitle center">
            Other players finishing up...
        </div>
        <div className='quipWaitingWrapper'>
    <l-tail-chase
      size="100"
      speed="1.75"
      color="black" 
    ></l-tail-chase>     
        </div>
        </>
    }

    function renderPageContent(){
        if(collectingPrompt){
            return renderPromptCollection()
        }
        if(voting){
            return renderVote()
        }
        if(displayingResults){
            return renderDisplayingResults()
        }
        if(waiting){
            return renderWaiting()
        }
    }
    window.scrollTo(0, 0);
    return (
    <Page className="challengeContainer" style={{ transform: props.flipped ? 'rotateY(180deg)' : '' }}>
        {renderPageContent()}
    </Page>
    );
}

export default QuipChallenge;