import styled from "styled-components";
import React from 'react';
import DrEvent from "../../display/DrEvent";
const Page = styled.div`
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5 !important;
`;

function ChoiceChallenge(props) {

    const handleSubmit = (e) =>{
        e.preventDefault();
        let choicePicked = e.currentTarget.getAttribute('vote-id');
        e.currentTarget.style.backgroundColor = "#FFFF66";
        e.currentTarget.style.color = 'white';
        let payload = props.challengeJson;
        payload['userInput'] = choicePicked;
        payload['type'] = "chal update"
        payload['turnPage'] = true;
        props.sendEvent("chal action", payload)
    }

    function renderVotingOptions() {
        let options = [];
        props.choices.forEach((option) => {
                options.push(
                    <button className='choiceChalOption' 
                    disabled = {props.yourId!==props.turnPlayer}
                    vote-id={option} 
                    key={option} 
                    onClick={handleSubmit}>
                        {option}
                    </button>
            )
        })
        return options;
    }
    
    function highlight(text){
        const groupNameStyle = {color: "#FF3333" }
        const playerNameStyle = {color: props.playerColor }
        text = text.replace(/<any>/g,`<span style="${Object.entries(groupNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">Anyone</span>`);
        text = text.replace(/<all>/g,`<span style="${Object.entries(groupNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">Everyone</span>`);
        return text.replace(/<name>/g,`<span style="${Object.entries(playerNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">${props.turnPlayerName}</span>`);
    }

    return (
        <DrEvent className="challengeContainer"
            style={{ transform: props.flipped ? 'rotateY(180deg)' : '' }}>
            <div className='chalTitleContainer'>
                {props.title}
            </div>
            <div className='choiceChalPrompt'>
                <p dangerouslySetInnerHTML={{__html:highlight(props.prompt)}}></p>
            </div>
            <div className='choiceChalContent'>
                    {renderVotingOptions()}                
            </div>
        </DrEvent>
    )
}

export default ChoiceChallenge;