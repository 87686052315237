
import React, {useEffect, useState, useRef} from 'react';
import styled from "styled-components";

const Page = styled.div`
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
`;



function Chance(props) {
    const [pickingPlayer, setPickingPlayer] = useState(true)
    const [pickingChance, setPickingChance] = useState(true)
    const [curPlayer, setCurPlayer] = useState("")
    const [curChance, setCurChance] = useState("")
    const [curId, setCurId] = useState(null)

    const pickingPlayerRef = useRef(pickingPlayer)
    const pickingChanceRef = useRef(pickingChance)

    useEffect(()=>{
        pickingPlayerRef.current = pickingPlayer
    },[pickingPlayer])

    useEffect(()=>{
        pickingChanceRef.current = pickingChance
    },[pickingChance])

    useEffect(()=>{
        if(props.socketData && props.socketData.type==="chance update"){
            if(props.socketData.hasOwnProperty("name")){
                setPickingPlayer(false)
                setCurPlayer(props.socketData.name)
            }
            if(props.socketData.hasOwnProperty("chance")){
                setPickingChance(false)
                setCurChance(props.socketData.chance)
            }
        }
    },[props.socketData])

    useEffect(()=>{
        spinPlayer(0)
        spinChance(0)
    },[])

    async function spinPlayer(i){
        setCurPlayer(props.playerList[i%props.playerList.length].name)
        setCurId(props.playerList[i%props.playerList.length].id)
        setTimeout(()=>{
            if(pickingPlayerRef.current){
                spinPlayer(++i)
            }
        },75)
    
    }

    async function spinChance(i){
        setCurChance(props.chanceList[i%props.chanceList.length])
        setTimeout(()=>{
            if(pickingChanceRef.current){
                spinChance(++i)
            }
        },75)
    }

    function handleStopPlayer(e){
        e.preventDefault()
        setPickingPlayer(false)
        const payload = {
            type: "chance update",
            name : curPlayer,
            id : curId
        }
        props.sendEvent('chance action', payload)
        if(!pickingChanceRef.current){
            handleResult()
        }
    }

    function handleStopChance(e){
        e.preventDefault()
        setPickingChance(false)
        const payload = {
            type: "chance update",
            chance : curChance
        }
        props.sendEvent('chance action', payload)
        if(!pickingPlayerRef.current){
            handleResult()
        }
    }

    function handleResult(){
        if(curId){
            console.log("Handle playerId not null")
            let payload =  {}
            payload['originalUser'] = curId
            if(curChance.includes("Trades")){
                if(curChance.includes("first")){
                    payload['swapPlace'] = 'first'
                }
                if(curChance.includes("last")){
                    payload['swapPlace'] = 'last'
                }
                props.sendEvent("position swap", payload)
            }

            if(curChance.includes("reverse")){
                console.log(`Sending cursed dice to ${curId}`)
                props.sendEvent("cursed dice given", payload)
            }
        }
    }


    return (
     <Page className='rulesContainer'>
        <div className='chalTitleContainer'>
            Chance
        </div>
        <div className='promptSubtitle'>
       <span style={{fontStyle:"none"}}></span>{props.turnPlayerName} picks a mystery prize <span style={{fontStyle:"none"}}></span>
        </div>
        <div className='chanceContent'>
            <div className='chancePicker'>
                <div className='chanceText'> <br/>               
                {pickingPlayer ? <div className= 'chanceBtn' onClick={props.turnPlayer === props.yourId ? handleStopPlayer : null}>{
                
                <span>{curPlayer}</span>
                }</div>:
                <div className= 'chanceResult'>
                {curPlayer}
                </div>}
                </div>
            </div>
            <div className='chancePicker'>
            <div className='chanceText'>  <br/>  
                {pickingChance ?<button className= 'chanceBtn' onClick={props.turnPlayer === props.yourId ? handleStopChance :null}>{
                <span>{curChance}</span>

                }</button>:
                <div className= 'chanceResult'>
                {curChance}
                </div>}
            </div>
        </div>
        </div>
        {(props.yourId===props.turnPlayer) && (!pickingChance && !pickingPlayer) ?
            <button  className='splashNavBtn' onClick={props.handleNextBtn}>DONE</button>
        :null}
        </Page>
    )
}

export default Chance;