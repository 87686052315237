import React from "react";
import styled from "styled-components";
import { LeapFrog } from '@uiball/loaders';
import '../App.css';

const Page = styled.div`
    height: ${window.innerHeight}px;
    width: 100vw;
    background-color: black;
    display: flex;;
    justify-content: center;
    overflow-y:hidden;
    overflow-x:hidden;
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items:center;
    display: flex;
    flex-direction: column;
`

function WaitingRoom(props){
    return (
        <Page>
            <Content >
                <LeapFrog 
                size={80}
                speed={2.0} 
                color="#99ffcc" 
                />
                <div style={{margin: "3vh"}} className="gamePiecePrompt"> Jumping in next turn</div>
                <button onClick={props.handleDetatch} className="reconnectBtn">Quit Game</button>
            </Content>
        </Page>
    )
}

export default WaitingRoom;