
import styled from "styled-components";
import React,{ useEffect, useState } from 'react';

import { Chess } from "chess.js";


const Page = styled.div`
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

function FullscreenChallenge(props) {
    const [fen, setFen] = useState(props.fenList[0]);
    const [doneMoving, setDoneMoving] = useState(false);
    const [displayExit, setDisplayExit] = useState(false);


    const chess = new Chess(fen);
    const turnColor = chess.turn() === "w" ? "white" : "var(--offBlack)";
    const handleMove = (from, to) => {
        setDoneMoving(true);
        let validated = false;
        chess.moves({square:from}).forEach((option)=>{
            if(option.includes(to)){
                console.log(option)
                validated = true;
                let payload = props.challengeJson;
                payload['userInput'] = {from, to};
                payload['type'] = "chal update";
                payload['turnPage'] = false;
                props.sendEvent("chal action", payload);

                if(props.yourId === props.turnPlayer){
                    chess.move({from,to});
                    setFen(chess.fen());
                    setDoneMoving(true);
                    setDisplayExit(true);
                }
            }
        });
        if(!validated){
            //Illegal move, try again
            setDoneMoving(false)
            setFen(props.fenList[0]);
        }
    };

    useEffect(()=>{
            if(props.socketData && props.socketData['type'] === 'chal update'){
                if(props.yourId !== props.turnPlayer){
                    setDoneMoving(true);
                    let {from, to} = props.socketData['userInput']
                    chess.move({from,to});
                    setFen(chess.fen());
                } 
        }
    },[props.socketData])

    useEffect(()=>{
        if(doneMoving){
            moveResult(fen === props.fenList[1])
            console.log(fen+" "+props.fenList[1])
        }
    },[fen])

    function moveResult(correctMove){
        let resultText = document.querySelector('.subTitleContainer');
        if(correctMove){
            resultText.textContent = "CORRECT"
            resultText.style.color= "#FFFD20"
        } else {
            resultText.textContent = props.challengeJson['penalty']
            resultText.style.color= "#DD0426"
        }
    }
    let chessBoard = null
    /*<Chessground
        fen={fen}
        turnColor={turnColor}
        onMove={handleMove}
        width={"75vw"}
        height={"75vw"}
        coordinates = {false}
        viewOnly={doneMoving || (props.yourId!==props.turnPlayer)}
        movable={
            {color:"white",
            free: true}
        }
    />
    */

    return (
        <Page className="challengeContainer"
            style={{ transform: props.flipped ? 'rotateY(180deg)' : '' }}>
            <div className='chalTitleContainer'>
                {props.title}
            </div>
            <div className='subTitleContainer'>
                    {props.prompt}
                </div>
            <div className='fullscreenChalContent'>
                <>
                {chessBoard}
                {displayExit ? <button className='splashNavBtn' onClick={props.handleClose}>DONE</button>:null}
                </>
            </div>

        </Page>
    )
}

export default FullscreenChallenge;