import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DrEvent from '../../display/DrEvent';

const Page = styled.div`
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
`;

function TimedChallenge(props) {
  const [count, setCount] = useState(props.seconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count => count - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (count === 0) {
        const timer = document.querySelector('.timedChalText');
        timer.style.color = "var(--offBlack)"
    }
  }, [count]);

  function getPrompt(){
    if(count > 0){
      return props.prompt
    }
    return props.afterPrompt
  }

  function highlight(text){
    const groupNameStyle = {color: "white" }
    const playerNameStyle = {color: props.playerColor }
    text = text.replace(/<any>/g,`<span style="${Object.entries(groupNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">Anyone</span>`);
    text = text.replace(/<all>/g,`<span style="${Object.entries(groupNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">Everyone</span>`);
    return text.replace(/<name>/g,`<span style="${Object.entries(playerNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">${props.turnPlayerName}</span>`);
}
  return (
    <DrEvent className="challengeContainer" style={{transform: props.flipped ? 'rotateY(180deg)' : ''  }}>
      <div className='rulesTitleContainer' style={{textShadow: 'none' }}>
        {props.title}
      </div>
      <div className='promptRulesText'>
        <p className='rules' style={{color: "black"}} dangerouslySetInnerHTML={{__html: highlight(getPrompt())}}></p>
      </div>
      <div className='timedChalText'>  
          {count > 0 ? `${count}` : 'TIME!'}
      </div>
      {(props.yourId===props.turnPlayer) ?
        <button className='splashNavBtn' onClick={props.handleClose}>END TURN</button>
        : null}
    </DrEvent>
  );
}

export default TimedChallenge;
