import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Page = styled.div`
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
`;


function QuizChallenge(props) {
  function highlight(text){
      let groupNameStyle = {color: "#FF3333" }
      const playerNameStyle = {color: props.playerColor }
      text = text.replace(/<any>/g,`<span style="${Object.entries(groupNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">Anyone</span>`);
      text = text.replace(/<all>/g,`<span style="${Object.entries(groupNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">Everyone</span>`);
      return text.replace(/<name>/g,`<span style="${Object.entries(playerNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">${props.turnPlayerName}</span>`);
  }

    function renderAnswers(){
        let result = [];
        let numAnswers = props.varData.answer.length;
        props.varData.answer.forEach((answer)=>{
            result.push(
                <div className={numAnswers<=5?'quizAnswerBig':'quizAnswerMini'}>
                <label class="checkbox style-c">
                    <input type="checkbox"/>
                    <div class="checkbox__checkmark"></div>
                    <div class="checkbox__body">{answer}</div>
                </label>
                </div>
            )
        })
        return result
    }

  return (
    <Page className="challengeC4sontainer" style={{transform: props.flipped ? 'rotateY(180deg)' : ''  }}>
      <div className='rulesTitleContainer' style={{textShadow: 'none' }}>
        {props.title}
      </div>
      <div className='quizRulesText'>
        <p dangerouslySetInnerHTML={{__html:highlight(`${props.varData.prompt}\n\n${props.turnPlayer === props.yourId?"• Give out a drink after each correct answer and stop if you say one not in the list":"\n\n• Other players keep track:"}`)}}></p>
      </div>
      {props.turnPlayer === props.yourId?
        <button className='splashNavBtn' onClick={props.handleClose}>END TURN</button>
        :<div className={props.varData.answer.length <= 5?'quizAnswersBig':'quizAnswersMini'}>
            {renderAnswers()}
        </div>}
    </Page>
  );
}

export default QuizChallenge;
