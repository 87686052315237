import React, { useState, useEffect, useRef, useMemo } from 'react';
import DrEvent from '../../display/DrEvent';

const iconMap = ["🥂","🍾","🍹","🍷","🍺"];
const iconHighlight = [
    "#FFFF66",
    "lime",
    "#FF9933",
    "#FF33CC",
    "#FFFF66"
];

function generateGrid() {
    let grid = [];
    for(let i = 0; i < 98; i++) {
        let curNum = Math.floor(Math.random() * 5);
        grid.push(curNum);
    }
    return grid;
}

function HeroChallenge(props) {
    const [columns, setColumns] = useState([[], [], [], [], []]); // Single state for all columns


    const [countdown, setCountdown] = useState(3);
    const [activeBoard, setActiveBoard] = useState(false);

    const [step, setStep] = useState(0);
    const [allDone, setAllDone] = useState(false);

    const [pressedButtons, setPressedButtons] = useState(new Set());

    const stepRef = useRef(step);

    useEffect(() => {
        stepRef.current = step;
    }, [step]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (countdown === 0) {
                clearInterval(interval);
                setActiveBoard(true);
            } else {
                setCountdown(countdown => countdown - 1);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [countdown]);

    useEffect(() => {
        if (activeBoard) {
            const interval = setInterval(() => {
                if (step === props.iconGrid.length) {
                    setStep(step => step + 1);
                    setTimeout(() => {
                        if (!allDone) {
                            clearInterval(interval);
                            sendMyScore();
                            setAllDone(true);
                        }
                    }, 1500);
                } else {
                    if(step < props.iconGrid.length){
                        deleteButton(step)
                        addButton(props.iconGrid[step]);
                        setStep(step => step + 1);
                    }
                }
            }, 180);

            return () => {
                clearInterval(interval);
            };
        }
    }, [step, activeBoard]);

    function deleteButton(s){
        const element = document.getElementById(s-10);
        if (element) {
            element.style.display = "None"
        }
    }

    function handleHeroClick(e) {
        
        const btnId = e.target.id;
        const element = document.getElementById(btnId);
        if (element) {
            element.disabled = true;
            element.style.background = element.style.borderColor;
            element.style.boxShadow = `0 0 25px ${element.style.borderColor}`
            element.classList.add("hidden");
            if(!pressedButtons.has(String(btnId))){
                setPressedButtons(prev => prev.add(String(btnId)));
            }
        }
    }

    function renderCols(colNum) {
        columns[colNum].filter(item => !pressedButtons.has(String(item.props.id)));

        return columns[colNum].map(btn => btn);
    }

    function sendMyScore() {
        let score = pressedButtons.size ? pressedButtons.size : 1
        let payload = {
            userInput: score,
            type: "input submitted",
            displayType: "ASC",
            valLabel: 'pts'
        };
        props.sendEvent("input submitted", payload);
        props.handleNextBtn(false);
    }

    const isTouchDevice = () => {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
      };
    
    function addButton(colNum) {
        let newButton = (
            <button className='heroButton'
                 id={stepRef.current} 
                 onClick={!isTouchDevice() ? handleHeroClick : undefined}
                 onTouchStart={isTouchDevice() ? handleHeroClick : undefined}
                 style={{textShadow: `0 0 15px ${iconHighlight[colNum]}`, border: `4px solid ${iconHighlight[colNum]}`}}>
                {iconMap[colNum]}
            </button>
        );

        setColumns(prevCols => {
            let newCols = [...prevCols];
            if(newCols[colNum]){
                newCols[colNum] = [...newCols[colNum], newButton];
                return newCols;
            }
            return prevCols
        });
    }

    function renderTitle() {
        if (activeBoard) {
            return(
            <div className='heroTitleContainer'>
                Drink Hero
            </div>
            )
        }
        return (
        <div className='heroChalBanner'>
        {`Start in: ${countdown}`}
        </div>)
    }

    function renderContent() {
        return (
            <>
                {columns.map((col, index) => (
                    <div key={index} className='heroCol'>
                        <div className='heroString'/>
                        {renderCols(index)}
                    </div>
                ))}
            </>
        );
    }

    return (
        <DrEvent className="challengeContainer">
            {renderTitle()}
            <div className='heroContainer'>
                {renderContent()}
            </div>
        </DrEvent>
    );
}

export default HeroChallenge;
