import React, { Component } from 'react';

class DrContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPortrait: window.innerHeight > window.innerWidth
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      isPortrait: window.innerHeight > window.innerWidth
    });
  }

  render() {
    const { isPortrait } = this.state;
    const divStyle = isPortrait ? portraitStyle : landscapeStyle;

    return (
      <div style={divStyle}>
        {this.props.children}
      </div>
    );
  }
}

const portraitStyle = {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  zIndex: 2,
};

const landscapeStyle = {
    width: '50%',
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
    zIndex: 2
};

export default DrContent;