import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

const Page = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
`;

const iconPaths = [
    "game piece-0.png",
    "game piece-01.png",
    "game piece-02.png",
    "game piece-03.png",
    "game piece-04.png",
    "game piece-05.png",
    "game piece-06.png",
    "game piece-07.png",
    "game piece-08.png",
    "game piece-09.png",
    "game piece-10.png",
    "game piece-11.png",
    "portrait4x.png"
];

const Tile = styled.div`
width:100%;
height: 100%;
`

const RocketGrid = styled.div`
height: 70%;
width: 100%;
display: grid;
z-index: 0;
justify-content: center;
grid-auto-rows: auto;
grid-auto-columns: 6vw;
grid-template-areas:
"l14 . . . . . . . . . . . . . ar14"
"l13 . . . . . . . . . . . . ar13 ."
"l12 . . . . . . . . . . . ar12 . ."
"l11 . . . . . . . . . . ar11 . . ."
"l10 . . . . . . . . . ar10 . . . ."
"l9 . . . . . . . . ar9 . . . . ."
"l8 . . . . . . . ar8 . . . . . ."
"l7 . . . . . . ar7 . . . . . . ."
"l6 . . . . . ar6 . . . . . . . ."
"l5 . . . . ar5 . . . . . . . . ."
"l4 . . . ar4 . . . . . . . . . ."
"l3 . . ar3 . . . . . . . . . . ."
"l2 . ar2 . . . . . . . . . . . ."
"l1 ar1 . . . . . . . . . . . . ."
"ar0 . . . . . . . . . . . . . .";
/* hide the scrollbar */
::-webkit-scrollbar {
    display: none;
  }
`

function RocketChallenge(props) {
    const [crashed, setCrashed] = useState(false);
    const [curRocketNum, setCurRocketNum] = useState(-1);
    const [prelaunch, setPrelaunch] = useState(true);
    const [countdown, setCountdown] = useState(3)
    const [iCashedOut, setICashedOut] = useState(false);
    const [myCashOut, setMyCashOut] = useState(0);
    const [lobbyCashouts, setLobbyCashout] = useState([]);
    const [delayedButton, setDelayedButton] = useState(false);


      useEffect(() => {
        setCurRocketNum(0)
        const interval = setInterval(() => {
            if (countdown === 0) {
              clearInterval(interval);
              setPrelaunch(false);
            } else {
              setCountdown(countdown => countdown - 1);
            }
          }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, [countdown]);

    useEffect(()=>{
        if(props.socketData.hasOwnProperty("icon")){
            console.log(props.socketData.icon)
        const tile = document.querySelector(`[data-tile="ar${props.socketData.userInput+1}"]`);
        const { top, left, width, height } = tile.getBoundingClientRect();
        const cashOutIcon = (<img src={`/icons/${iconPaths[parseInt(props.socketData.icon)]}`}
        key={props.socketData.icon+props.socketData.userInput}
        style = {{ gridArea:`ar${props.socketData.userInput}`,
          position: "absolute",
          top: top-50, // position in the center of the tile
          left: left-50,
          width: "100px",
          height: "100px"
        }}/>)
        setLobbyCashout(prevCashouts => [...prevCashouts, cashOutIcon]);
        }
    },[props.socketData])

    useEffect(() => {
        if(!prelaunch){
            const interval = setInterval(() => {
                if(!crashed){
                    setCurRocketNum(curRocketNum => curRocketNum + 1);
                }
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [prelaunch]);

    useEffect(() => {
        if(curRocketNum === props.crashNum){
            setCrashed(true);
            setDelayedButton(true);
        }
    }, [curRocketNum]);

    useEffect(() => {
        if(delayedButton){
            const interval = setInterval(() => {
                setDelayedButton(false);
              }, 1000);
          return () => {
            clearInterval(interval);
          };
        }
    }, [delayedButton]);

    function getMovingPlayerStyles() {
        const tile = document.querySelector(`[data-tile="ar${curRocketNum}"]`);
        
        if (!tile){
            return null;
        }

        const { top, left, width, height } = tile.getBoundingClientRect();

        return (<div style = {{
          position: "absolute",
          top: top-75, // position in the center of the tile
          left: left-75,
          width: "150px",
          height: "150px",
          fontSize: "7vh",
          transition: "all 1000ms linear",
          alignContent:'center',
          justifyContent:'center'
        }}>
        🚀
        </div>)
      }

      const gridStops = useMemo(() => {
        let tiles = [];
        for(let i = 0; i<15; i++){
            tiles.push(<Tile data-tile={`ar${i}`} style ={{gridArea:"ar"+i}}/>)
        }
        return tiles
    }, []);

    function getLineBackground(i,cur){
        if(crashed&&i===props.crashNum){
            return "#FF3333"
        }
        if(!crashed && i<=cur){
            return "#99FFCC"
        }
        return "var(--offBlack)"
    }

    const lineDividers = useMemo(() => {
        let lines = [];
        for(let i = 1; i<15; i++){
            lines.push(<div style={{zIndex:"-1",backgroundColor: getLineBackground(i,curRocketNum) ,gridArea: `l${i}`, borderBottom: "1px white solid", width: "90vw", display: "flex", textAlign:"left",height:"100%"}}>
                {i}
            </div>)
        }

        return lines
    }, [crashed,curRocketNum]);

      const movingPiece = useMemo(() => {
        if(crashed){
            const tile = document.querySelector(`[data-tile="ar${props.crashNum}"]`);
            const { top, left, width, height } = tile.getBoundingClientRect();
            return <div
            style = {{
              position: "absolute",
              top: top-75, // position in the center of the tile
              left: left-75,
              width: "150px",
              height: "150px",
              fontSize: "9vh"
            }}>
            💥
            </div>
        }
        return getMovingPlayerStyles()
    }, [curRocketNum,crashed]);

    function handleCashOut(e){
        e.preventDefault()
        setICashedOut(true);
        setMyCashOut(curRocketNum);
        let myIcon = props.players.find(u => u.uid === props.yourId).iconId
        console.log(myIcon)
        let payload = {"icon":myIcon};
        payload['userInput'] = curRocketNum;
        payload['type'] = "chal update"
        props.sendEvent("chal action", payload) 
    }

    function renderBanner(){
        if(prelaunch){
            return `Launching in ${countdown}...`
        }
        if(iCashedOut && !crashed){
            return `Waiting...`
        }
        if(crashed){
           return iCashedOut? `Give ${myCashOut} drinks` : `Crashed! Take ${props.crashNum} drinks`
        }
        return `Current Drinks: ${curRocketNum}`
    }

    function renderButton(){
        if(crashed && (props.yourId === props.turnPlayer) && !delayedButton){
            return <button className='splashNavBtn' onClick={props.handleClose}>END TURN</button>
        }
        if(crashed){
            return <button className='splashNavBtn' style={{opacity: 0}}></button>
        }
        if(!iCashedOut && !prelaunch){
            return <button className='splashNavBtn' onClick={handleCashOut}>🍺 CASH OUT 🍺</button>
        }
        return <button className='splashNavBtn' style={{opacity: 0}}></button>
    }

    return (
        <Page className="challengeContainer"
        style={{ transform: props.flipped ? 'rotateY(180deg)' : '' }}>
            <div className='rulesTitleContainer' style={{textShadow: 'none', borderBottom: "none"}}>
                {renderBanner()}
            </div>
            <RocketGrid>
            {lineDividers}
            {gridStops} 
            {movingPiece}
            {lobbyCashouts}
            </RocketGrid>
            {renderButton()}

      </Page>
    )

    //First section will be the header of 
    //Starting in 3..|Current Drinks: 1.4|Waiting for Players...|Crashed! Drink 7|Give out 4 drinks
    //RocketGrid
    //Cashoout button, or nextPage if host and crash == true


};


export default RocketChallenge;