import React from 'react';
import styled from "styled-components";
import '../Splash.css';
const Page = styled.div`
    backface-visibility: hidden;
    position: absolute;
    top: 4.25%;
    left:0;
    width: 100vw;
    height: 96%;
    z-index: 5;
    display: flex;
    flex-direction: column;
    animation: fadeinBackground 1s forwards;
`;

function Leaderboard(props){

    function styledRank(num){
        const rankMap = {
            1: "1st",
            2: "2nd",
            3: "3rd",
            4: "4th",
            5: "5th",
            6: "6th",
            7: "7th",
            8: "8th",
            9: "9th",
            10: "10th",
            11: "11th",
            12: "12th"
        }
        return rankMap[num]
    }

    function sortPlayers(players){
        const filteredPlayers = Object.values(players).filter(player => player.connected);
        const sortedPlayers = filteredPlayers.sort((a, b) => b.position - a.position);
        const playerData = sortedPlayers.map(player => ({
            playerIcon: player.iconId,
            name: player.name,
            position: player.position
        }));
        playerData[0].prize = "Winner"
        if(props.dLvl > 0){
            playerData[0].prize = "Give a shot"
        }
        if(playerData.length > 1 && props.dLvl === 2){
            playerData[playerData.length-1].prize = 'Take a Shot'
        }
        return playerData
    }
    function renderUsers(){
        const userComponents = []
        let textDelay = 1;
        let players = sortPlayers(props.lobby.players)
        players.forEach((player,index)=>{
            userComponents.push(
                <div className='leaderboardPlayer' style={{opacity:0, animation: `reveal 1s ${textDelay}s forwards`}}>
                <div className='leaderboardPlayerName'>
                {styledRank(index+1)}:&nbsp;&nbsp;
                {player.name}
                </div>
                {player.prize?
                <span> 
                {player.prize}
                </span>:
                <span> 
                {`${index+1} drinks`}
                </span>  
                }
                </div>
            )
            textDelay+=.25;
        })

        return userComponents
    }


    function handleClose(e){
        e.preventDefault()
        props.sendEvent("end game",{})
    }  

return (
    <Page>
        <ul class="bubbles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
        </ul> 
        <div className="leaderboardTitle">
            Results
        </div>
        <div className='leaderboardPlayers'>
        {renderUsers()}

        </div>
        {props.yourId === props.turnPlayer ? <button className ="leaderboardNavBtn" onClick={handleClose}>New Game</button>:
        null}
    </Page>
)
}
export default Leaderboard