import styled from "styled-components";
import React, {useState, useEffect} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DrEvent from "../../display/DrEvent";
import DrContent from "../../display/DrContent";

const Page = styled.div`
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5 !important;
    overflow: hidden;
`;

function SortingChallenge(props) {
    const [boxes, setBoxes] = useState(props.varData.options);
    const [preCheck, setPreCheck] = useState(true);

    useEffect(() => {
        console.log(props.socketData)
        if (props.socketData && props.socketData.userInput) {
            setBoxes(props.socketData.userInput);
            setPreCheck(false);
        }
      }, [props.socketData]);


    const handleSubmit = (e) =>{
        e.preventDefault();
        console.log("Submitted")
        let payload = props.challengeJson;
        payload['userInput'] = boxes;
        payload['type'] = "chal update"

        props.sendEvent("chal action", payload)
    }

    function renderButton(){

        if(!preCheck && props.yourId===props.turnPlayer){
            return <button className='splashNavBtn' onClick={props.handleClose}>DONE</button>
        }
        if(preCheck && props.yourId===props.turnPlayer){
            return <button className='splashNavBtn' onClick={handleSubmit}>SUBMIT</button>
        }
        return <button className='splashNavBtn' style={{opacity: 0}}></button>
      }

    function renderPrompt(){
        if(preCheck){
            return props.prompt
        }
        let msg = "Correct! Give out a sip <name>"
        let wrong = false;
        boxes.forEach((option, index) => {
            if(wrong || option.rank !== index){
                wrong = true;
                msg = "Wrong! Take a sip <name>"
            } 
        });
        return msg
    }

    function handleOnDragEnd(result) {
      if (!result.destination) return;
      window.scrollTo(0, 0);
      const items = Array.from(boxes);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
  
      setBoxes(items);
    }

    function highlight(text){
      const groupNameStyle = {color: "#FF3333" }
      const playerNameStyle = {color: props.playerColor }
      text = text.replace(/<any>/g,`<span style="${Object.entries(groupNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">Anyone</span>`);
      text = text.replace(/<all>/g,`<span style="${Object.entries(groupNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">Everyone</span>`);
      return text.replace(/<name>/g,`<span style="${Object.entries(playerNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">${props.turnPlayerName}</span>`);
  }

    function renderBeautifulDnd(){
      const isMobile = window.innerHeight > window.innerWidth;
      return <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters">
            {(provided) => (
              <ul className="draggingContainer" {...provided.droppableProps} ref={provided.innerRef}
              style={{width: (isMobile? "80vw": "30vw")}}>
                {boxes.map(({rank, name, value}, index) => {
                  return (
                    <Draggable isDragDisabled={!preCheck || (props.yourId!==props.turnPlayer)} key={rank} draggableId={name} index={index}>
                      {(provided) => (
                        <li className="draggingContainer" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <div className='sortingChalOption'
                           style={{backgroundColor: preCheck?"black":(rank === index?"#66cc66":"#ff3333"),
                                   color: preCheck?"white":"black"}} >
                            {preCheck?name:name+":  "+value}
                          </div>
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
      </DragDropContext>
    }

    return (
        <Page className="challengeContainer"
            style={{ transform: props.flipped ? 'rotateY(180deg)' : '' }}>
            <div className='chalTitleContainer'>
                {props.title}
            </div>
            <div className='sortingChalPrompt'>
                <p dangerouslySetInnerHTML={{__html:highlight(renderPrompt())}}></p>
            </div>
            <div className='sortingContentWrapper'>
            <div className='sortScaleA'>{props.varData.scaleA}</div>
            <div className='beautifulSortingContainer'>
                {renderBeautifulDnd()}
            </div>
            <div className='sortScaleB'>{props.varData.scaleB}</div>
  
            </div>
            {renderButton()}
        </Page>
    )
}

export default SortingChallenge;