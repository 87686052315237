import React, { useEffect } from "react";

const CacheHelp = (props) => {


  function handleBack(e){
    const container = document.querySelector('.cacheHelpContainer');
    container.style.opacity = 1;
    container.style.animation = "fadeOut .5s linear .1s 1 forwards"
    setTimeout(()=>{
      props.handleBack(e)
    },750)

  }
  return (
    <div className="cacheHelpContainer">
        <div className="cacheHelpText">
        Drink Royale has added more minigames since you last played:
        </div>
    <div className="cacheHelpText">
        1. Close this tab and search drinkroyale.com again
    </div>
    <div className="cacheHelpText">
        2. Refresh the page a few times
    </div>
    <div className="cacheHelpText">
        3. Repeat if this error persists
    </div>
    </div>
  );
};

export default CacheHelp;
