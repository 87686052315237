import styled from "styled-components";
import React from 'react';
import DrEvent from "../../display/DrEvent";

const Page = styled.div`
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
`;

function PromptChallenge(props) {
    function highlight(text){
        const groupNameStyle = {color: "white" }
        const playerNameStyle = {color: props.playerColor }
        text = text.replace(/<any>/g,`<span style="${Object.entries(groupNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">Anyone</span>`);
        text = text.replace(/<all>/g,`<span style="${Object.entries(groupNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">Everyone</span>`);
        return text.replace(/<name>/g,`<span style="${Object.entries(playerNameStyle).map(([key, value]) => `${key}:${value}`).join(';')}" class="playerName">${props.turnPlayerName}</span>`);
    }
return (
    <DrEvent className="challengeContainer"
        style={{ transform: props.flipped ? 'rotateY(180deg)' : '' }}>
        <div className='rulesTitleContainer' style={{textShadow:'none'}}>
                {props.title}
        </div>
        <div style={{height:"20%"}}className='rulesText'>

            <p className='rules' dangerouslySetInnerHTML={{__html: highlight(props.rules)}}></p>
        </div>
        <div className='promptChalText'>
                <p className='prompt'>{props.prompt}</p>
        </div>
        {(props.yourId===props.turnPlayer) ?
        <button className='splashNavBtn' onClick={props.handleClose}>END TURN</button>
        :null}
    </DrEvent>
)
}

export default PromptChallenge;