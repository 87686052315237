import React, { useState, useRef  , useEffect } from 'react';
import DrEvent from "../../display/DrEvent";
import "../../Lock.css";

function PoplockChallenge(props) {

    const colors = {
        green: {
          body: "hsl(168, 75%, 42%)",
          lock: "hsl(210, 61%, 18%)"
        },
        violet: {
          body: "hsl(267, 35%, 39%)",
          lock: "hsl(220, 86%, 11%)"
        },
        orange: {
          body: "hsl(24, 62%, 53%)",
          lock: "hsl(35, 20%, 12%)"
        },
        blue: {
          body: "hsl(186, 85%, 37%)",
          lock: "hsl(32, 67%, 8%)"
        },
        olive: {
          body: "hsl(55, 16%, 41%)",
          lock: "hsl(0, 0%, 0%)"
        }
      };
      
      const baseSpeed = 0.05;
      const notchWidth = 0.25;
      
        const [level, setLevel] = useState(parseInt(window.localStorage.getItem('level')) || 1);
        const [count, setCount] = useState(level);
        const [status, setStatus] = useState('start');
        const [linePosition, setLinePosition] = useState(0);
        const [notchPosition, setNotchPosition] = useState(0);
        const [clockwise, setClockwise] = useState(true);
      
        const lineDialRef = useRef(null);
        const notchDialRef = useRef(null);
      
        useEffect(() => {
          window.localStorage.setItem('level', level);
        }, [level]);
      
        useEffect(() => {
          function step() {
            if (status === 'move') {
              const newLinePosition = linePosition + baseSpeed * (clockwise ? 1 : -1);
              setLinePosition(newLinePosition);
              lineDialRef.current.style.transform = `rotate(${newLinePosition}rad)`;
      
              const angleDifference = Math.atan2(Math.sin(newLinePosition - notchPosition), Math.cos(newLinePosition - notchPosition));
              if (angleDifference * (clockwise ? 1 : -1) > notchWidth) {
                setStatus('fail');
              }
            }
            requestAnimationFrame(step);
          }
          step();
        }, [linePosition, notchPosition, clockwise, status]);
      
        useEffect(() => {
          if (status === 'start') {
            if (level < 10) setColor('green');
            else if (level < 20) setColor('violet');
            else if (level < 30) setColor('orange');
            else if (level < 40) setColor('blue');
            else setColor('olive');
      
            setCount(level);
            setLinePosition(0);
            setClockwise(true);
            generateNewNotch();
            document.body.classList.remove('fail');
          } else if (status === 'fail') {
            document.getElementById('notch').style.display = 'none';
            document.body.classList.add('fail');
          } else if (status === 'win') {
            document.getElementById('notch').style.display = 'none';
            document.body.classList.add('next');
            document.getElementById('shackle').classList.add('unlock');
            setTimeout(() => {
              setLevel(prevLevel => prevLevel + 1);
              setStatus('start');
              document.getElementById('shackle').classList.remove('unlock');
            }, 1000);
            setTimeout(() => {
              document.body.classList.remove('next');
            }, 2000);
          }
        }, [status, level]);
      
        const setColor = color => {
          document.body.style.backgroundColor = colors[color].body;
          document.getElementById('shackle').style.borderColor = colors[color].lock;
          document.getElementById('lock').style.borderColor = colors[color].lock;
        };
      
        const generateNewNotch = () => {
          const newNotchPosition = (((Math.random() * 0.75 * Math.PI) + 0.25 * Math.PI) * ((clockwise ? 1 : -1))) + linePosition;
          setNotchPosition(newNotchPosition);
          notchDialRef.current.style.transform = `rotate(${newNotchPosition}rad)`;
          document.getElementById('notch').style.display = 'block';
          document.getElementById('notch').classList.toggle('appear-b');
        };
      
        const handleClick = () => {
          if (status === 'start') {
            setStatus('move');
          } else if (status === 'move') {
            const angleDifference = Math.abs(Math.atan2(Math.sin(linePosition - notchPosition), Math.cos(linePosition - notchPosition)));
            if (angleDifference < notchWidth) {
              setCount(prevCount => prevCount - 1);
              if (count - 1 === 0) {
                setStatus('win');
              } else {
                setClockwise(!clockwise);
                generateNewNotch();
              }
            } else {
              setStatus('fail');
            }
          } else if (status === 'fail' || status === 'win') {
            setStatus('start');
          }
        };
      
        useEffect(() => {
          window.addEventListener('mousedown', handleClick);
          window.addEventListener('touchstart', e => {
            e.preventDefault();
            handleClick();
          });
          window.addEventListener('keydown', handleClick);
      
          return () => {
            window.removeEventListener('mousedown', handleClick);
            window.removeEventListener('touchstart', handleClick);
            window.removeEventListener('keydown', handleClick);
          };
        }, [handleClick]);
      
        return (
            <div className="lock-game">
                <section id="label">
                <div>Level: <span id="level">{level}</span></div>
                </section>
                <section id="padlock">
                <div id="shackle"></div>
                <div>
                    <div id="notch-dial" className="dial" ref={notchDialRef}>
                    <div id="notch" className="appear-a"></div>
                    </div>
                    <div id="line-dial" className="dial" ref={lineDialRef}>
                    <div id="line"></div>
                    </div>
                </div>
                <div id="lock">{count}</div>
                </section>
            </div>
        );
      };

export default PoplockChallenge;
