
import React, { useState} from 'react';

function LifeRaft(props) {

    const [promptId, setPromptId] = useState(-1)

    function nextPrompt(e){
        e.preventDefault()
        setPromptId(promptId+1)
        const lifeRaftBtn = document.querySelector(".lifeRaftButton")
        lifeRaftBtn.classList.add("shakeElement")
        var animationDuration = 300; // Duration in milliseconds (1 second in this example)
        setTimeout(function() {
            lifeRaftBtn.classList.remove('shakeElement');
        }, animationDuration);
    }

    const prompts = props.lifeRaftPrompts
    return (
            <button className="lifeRaftButton" onClick={nextPrompt}>{promptId>-1?prompts[promptId%prompts.length]:"Suggestions"}</button>
    );
};

export default LifeRaft;
