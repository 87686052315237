import React, { useEffect, useState, useMemo } from 'react';
import styled from "styled-components";
import LobbyGrid from './LobbyGrid.js';
import DrPage from '../display/DrPage.js';
import DrEvent from '../display/DrEvent.js';


const isMobile = window.innerWidth < window.innerHeight;
let usersW = "60%";
let usersM = "20vw"
if(isMobile){
    usersW = "100%";
    usersM = "0";
}


const BodyContainer = styled.div`
    width: 100%;
    height: 90%;
    overflow: auto;
    background: var(--offBlack);
    color:white;
    display: block;
    justify-content: center;
    flex-direction: row;
    overflow-x:hidden;
    opacity: 0;
    animation: fadeIn .75s linear 1 forwards;
`;

const LobbyBanner = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center; 
    justify-content: center;
    background-color:var(--offBlack);
    opacity: 0;
    animation: fadeIn 1s linear 1 forwards;
`;

const IconContainer = styled.div`
    background-color: var(--offBlack);
    width:100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(auto, 25%));
    grid-auto-rows: 33%;
    grid-gap: 0px;
    overflow-y: hidden;
    overflow-x: hidden;
    opacity: 0;
    animation: fadeIn 1s linear 1 forwards;
`;

const pathToIconFile = "/icons/"
const iconSelectPaths = [
    "game piece-0.png",
    "game piece-01.png",
    "game piece-02.png",
    "game piece-03.png",
    "game piece-04.png",
    "game piece-05.png",
    "game piece-06.png",
    "game piece-07.png",
    "game piece-08.png",
    "game piece-09.png",
    "game piece-10.png",
    "game piece-11.png",
    "portrait4x.png"
];

const iconLobbyPaths = [
    "game piece-0.png",
    "game piece-01.png",
    "game piece-02.png",
    "game piece-03.png",
    "game piece-04.png",
    "game piece-05.png",
    "game piece-06.png",
    "game piece-07.png",
    "game piece-08.png",
    "game piece-09.png",
    "game piece-10.png",
    "game piece-11.png",
    "portrait4x.png"
];

const playerColors =[
    "#00FFFF",
    "#FF0000",
    "#FFFF00",
    "#99FF00",
    "#339933",
    "#FF9450",
    "#990050",
    "#FF9900",
    "#9966FF",
    "#FFFFFF",
    "#FF99C3",
    "#0066CC"
]

const playerColorAlphas =[
    "rgb(255, 253, 32, .05)",//bee
    "rgb(221, 4, 38, .05)",//bull
    "rgb(53, 161, 255, .05)",//robot
    "rgb(255, 255, 255, .05)",//bird
    "rgb(0, 255, 50, .05)",//snake
    "rgb(196, 154, 108, .05)",//cowboy
    "rgb(236, 0, 140, .05)",//worm
    "rgb(247, 148, 29, .05)",//chess
    "rgb(209, 211, 212, .05)",//forklift
    "rgb(0, 234, 244, .05)",//octopus
    "rgb(0, 161, 75, .05)",//lime
    "rgb(198, 106, 255, .05)"//eggplant
]



function Lobby(props){
    const [chattyMessage, setChattyMessage] = useState("Waiting for host to start...");

    useEffect(()=>{
        pickChattyMessage(0, document.querySelector(".chattyMsg"))
    },[])

    let lobbyUsers = useMemo(() => {
        return <LobbyGrid 
        users={props.allUsers}
        chooseBackupHost={props.chooseBackupHost}
        isHost = {props.isHost}
        backupHost = {props.backupHost}
        roomCode = {props.roomCode}
        />
    }, [props.allUsers, window.innerHeight]);


    function pickChattyMessage(i, msgDiv){
/*
            "Welcome to the fucking show",
            "Tip: Don't let your phone sleep",
            "Tip: Have shots/glasses ready to go",
            "Tip: Sit in a circle",  
            "Tip: Avoid leaving the browser",
            "She Drink on my Royale till I .com",
            "Tip: Don't be a hero",
            "Tip: Keep it light, use beers/seltzers"
*/
        const msg = [
            "Tip: Have shots/glasses ready to go",
            "Tip: Screen share to a TV",
            "She Drink on my Royale till I .com",
            "Tip: You'll lose a turn if you leave the page",
            "Tip: Remember to end your turn",
            "Tip: Know your limits",
            "Tip: Don't let your phone sleep"
        ]
        setTimeout(()=>{
            if(!msgDiv){
                msgDiv = document.querySelector(".chattyMsg")
            }
            if(msgDiv){
                msgDiv.style.opacity = 1
            if(i%2===1){
                setChattyMessage(msg[i%msg.length])
            } else {
                setChattyMessage("Waiting for host to start...")
            }
            }
        },750)
        setTimeout(()=>{
            if(!msgDiv){
                msgDiv = document.querySelector(".chattyMsg")
            }
            msgDiv?msgDiv.style.opacity = 0:console.log()
            pickChattyMessage(++i, msgDiv)
        },7000)
      }

    function renderUser(user,index){
        let nameDisplay = "[open]"
        let hex = "#D1D3D4";
        let iconSrc = pathToIconFile+iconLobbyPaths[12];
        let id = user? user.uid: index;
        
        if(user){
            nameDisplay = user.name;
            if(user.isHost){
                nameDisplay = "Host: "+user.name;
            }
            iconSrc = pathToIconFile+iconLobbyPaths[user.iconId]
            if(user.iconId===-1){
                iconSrc = pathToIconFile+"portrait4x.png"
            } 
             hex = playerColors[user.iconId];
        }       

        
        return (
            <div className ="lobbyUserInfo" key={id} style={{backgroundColor: playerColorAlphas[user.iconId]}}>
                    <img className ="lobbyUserIcon"
                    height="100%"
                    width="auto"
                    src={iconSrc} 
                    alt={"playerIcon"+id}></img>
                    <p className="lobbyUserName"
                        style ={{color:"white"}}> {nameDisplay} </p>
            </div>
        );
    };

    function renderUsers(){
        const yourUser = props.allUsers.find(u => u.uid === props.yourId);
        if(yourUser && yourUser.iconId===-1){
            return;
        }
               /* 
        let users = []
        for(let i =0;i<12;i++){
            if(props.allUsers[i] && props.allUsers[i].connected){
                users.push(renderUser(props.allUsers[i],i))
            } else {
                users.push(renderUser(false,i));
            }
        }

            <UsersContainer>
                    {users}
            </UsersContainer>
        */
        return (
            <>
            {lobbyUsers}
            </>
        )
    }



    function allPlayersReady(){
        for(let i =0;i<12;i++){
            if(props.allUsers[i] && props.allUsers[i].connected && props.allUsers[i].iconId === -1){
                return false
            } 
        }
        return true;
    }

    function getIconRatio(i) {
        let h = "auto";
        let w = "100%";
        return (
          <div style={{ position: "relative" }}>
            <img
              height={h}
              width={w}
              style={{ position: "absolute", top: 0, left: 0, zIndex: 0 }}
              src={pathToIconFile + iconSelectPaths[i]}
              alt={"playerIcon" + i}
            />
            <button
              key={i}
              id={i}
              onClick={props.chooseIcon}
              className="iconSelectButton"
              style={{
                position: "absolute",
                zIndex: 1,
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
                border: "none",
              }}
            ></button>
          </div>
        );
    }

    function renderDLvlLabel(){
        switch(props.dLvl){
            case 0:
                return "\"Low\" : No shots and less drinking"
            case 1:
                return "\"Standard\" : You might take a shot"
            case 2:
                return "\"Heavy\" : Take shots and drink heavily"
            default:
                return "Choose"
        }
    }

    function renderSLvlLabel(){
        switch(props.sLvl){
            case 0:
                return "\"Mild\" : Almost family friendly"
            case 1:
                return "\"Standard\" : A lil spicy"
            case 2:
                return "\"Caliente\" : Wildly Spicy"
            default:
                return "Choose"
        }
    }

    function renderMapLabel(){
        if(props.miniBoard===null){
           return "Pick to view details\n"
        }
        if(props.miniBoard){
            return 'Endless mini games'
        }
        return 'Board game race to the finish'
    }

    function getMapStyle(id){
        if(props.miniBoard===null){
            return {
                opacity: ".2"
            } 
        }
        if(props.miniBoard){
            if(id==='miniBoard'){
                return {
                    opacity: "1"
                } 
            }
            return {opacity:'.2'}
        } else {
            if(id==='miniBoard'){
                return {opacity:'.2'}
            } else {
                return {
                    opacity: "1"
                } 
            }
        }
    }

    function readyForSave(){
        return props.miniBoard!==null && props.dLvl>=0 && props.sLvl>=0
    }

    function readyForSLvl(){
        return props.miniBoard!==null && props.dLvl>=0
    }

    function readyForDLvl(){
        return props.miniBoard!==null
    }

    function getDLvlContainerStyle(){
        if(readyForDLvl()){
            return {
                opacity: "1"
            } 
        }
        return {opacity: "0"}
    }

    function getSLvlContainerStyle(){
        if(readyForSLvl()){
            return {
                opacity: "1"
            } 
        }
        return {opacity: "0"}
    }

    function getSaveStyle(){
        
        if(readyForSave()){
            return {
                opacity:1
            }
        }
        else{
            return {opacity: 0}
        }
    }

    function getDLvlStyle(id){
        if(id===props.dLvl){
            return {
                opacity: "1"
            }   
        } else {
            return {
                opacity: ".25"
            }
        }
    }
    function getSLvlStyle(id){
        if(id===props.sLvl){
            return {
                opacity: "1"
            }   
        } else {
            return {
                opacity: ".2"
            }
        }
    }
/*


                <div className='lvlPickContainer'>
                <p className = 'lvlPickLable'>
                        1. Pick game style
                    </p>
                    <p className = 'lvlLable'>
                        {renderMapLabel()}
                    </p>
                    <div className='lvlPickBtnContainer'>
                        <button id='miniBoard' style={getMapStyle('miniBoard')} className='lvlPickBtn' onClick={props.chooseMap}> 🎉 </button>
                        <button id='bigBoard' style={getMapStyle('bigBoard')} className='lvlPickBtn' onClick={props.chooseMap}> 🏁 </button>
                    </div>

                </div>

*/
/*     review settings
                <div className='lvlPickContainer'>
                    <p className = 'lvlPickLable'>
                        HOW MUCH ARE YOU DRINKING?
                    </p>
                    <p className = 'lvlLable'>
                        {renderDLvlLabel()}
                    </p>
                    <div className='sliderScale'>
                    🧃
                    <input type="range" 
                    min={0} 
                    max={2}
                    step={1}
                    value={props.dLvl}
                    onChange={props.chooseDLvl}
                    className='lvlSlider'
                    style={{'--val': props.dLvl}}/>
                    🥃
                    </div>
                </div>
*/
    function renderLvlSliders(){
       return(<div className='lvlContainer'>
                <div className='lvlPickContainer'>
                    <p className = 'lvlPickLable'>
                        HOW MUCH ARE YOU DRINKING?
                    </p>
                    <p className = 'lvlLable'>
                        {renderDLvlLabel()}
                    </p>
                    <div className='sliderScale'>
                    🧃
                    <input type="range" 
                    min={0} 
                    max={2}
                    step={1}
                    value={props.dLvl}
                    onChange={props.chooseDLvl}
                    className='lvlSlider'
                    style={{'--val': props.dLvl}}/>
                    🥃
                    </div>
                </div>
                <div className='lvlPickContainer'>
                <p className = 'lvlPickLable'>
                        AFTER DARK CONTENT?
                    </p>
                    <p className = 'lvlLable'>
                        {renderSLvlLabel()}
                    </p>
                    <div className='sliderScale'>
                    😇
                    <input type="range" 
                    min={0} 
                    max={2}
                    step={1}
                    value={props.sLvl}
                    onChange={props.chooseSLvl}
                    className='lvlSlider'
                    style={{'--val': props.sLvl}}/>
                    💋
                    </div>
                </div>
                
                <button className='lvlButton' onClick={props.handleLvlNext}>NEXT</button>
        </div>)
    }


    function renderLvlPicking(){
        return (<div className='lvlContainer'>
                <div className='lvlPickContainer' style={{borderTop: "2px solid #151515"}}>
                <p className = 'lvlPickLable'>
                        1.) Pick Game Length
                    </p>
                    <div className='lvlPickBtnContainer'>
                        <button id='bigBoard' style={getMapStyle('bigBoard')} className='lvlPickBtn' onClick={props.chooseMap}> 🏁 </button>
                        <button id='miniBoard' style={getMapStyle('miniBoard')} className='lvlPickBtn' onClick={props.chooseMap}> ⚡ </button>
                    </div>
                    <p className = 'lvlLable'>
                        {renderMapLabel()}
                    </p>
                </div>
                <div className='lvlPickContainer' style={getDLvlContainerStyle()}>
                    <p className = 'lvlPickLable'>
                        2.) How much do you want to drink?
                    </p>
                    <div className='lvlPickBtnContainer'>
                        <button id='0' style={getDLvlStyle(0)} className='lvlPickBtn' onClick={readyForDLvl()?props.chooseDLvl:null}> 🧃 </button>
                        <button id='1' style={getDLvlStyle(1)} className='lvlPickBtn' onClick={readyForDLvl()?props.chooseDLvl:null}> 🥂 </button>
                        <button id='2' style={getDLvlStyle(2)} className='lvlPickBtn' onClick={readyForDLvl()?props.chooseDLvl:null}> ☠️ </button>
                    </div>
                    <p className = 'lvlLable'>
                        {renderDLvlLabel()}
                    </p>
                </div>
                <div className='lvlPickContainer' style={getSLvlContainerStyle()}>
                <p className = 'lvlPickLable'>
                        3.) Include spicy prompts?
                    </p>
                    <div className='lvlPickBtnContainer' >
                        <button id='0' style={getSLvlStyle(0)} className='lvlPickBtn' onClick={readyForSLvl()?props.chooseSLvl:null}> 🧊 </button>
                        <button id='1' style={getSLvlStyle(1)} className='lvlPickBtn' onClick={readyForSLvl()?props.chooseSLvl:null}> 🌶️ </button>
                        <button id='2' style={getSLvlStyle(2)} className='lvlPickBtn' onClick={readyForSLvl()?props.chooseSLvl:null}> 🔥 </button>
                    </div>
                    <p className = 'lvlLable'>
                        {renderSLvlLabel()}
                    </p>
                </div>
                
                <button className='lvlButton' style={getSaveStyle()}onClick={readyForSave()?props.handleLvlNext:null}>SAVE</button>
        </div>)
    }

        function renderIconButtons() {
            const yourUser = props.allUsers.find((u) => u.uid === props.yourId);
            if ((yourUser && yourUser.iconId !== -1) || !props.availableIcons) {
              return null;
            }
            const buttons = [];
            for (let i = 0; i < props.availableIcons.length; i++) {
              if (props.availableIcons[i]) {
                buttons.push(getIconRatio(i));
              }
            }
            return <IconContainer>{buttons}</IconContainer>;
        }

    function renderStartGameButton(){
        const yourUser = props.allUsers.find(u => u.uid === props.yourId);
        if(yourUser && yourUser.isHost && allPlayersReady()){
            return (
                <button id="startGame" className="startGameButton" onClick={props.startGame}>Start Game</button>
            );
        } else if(yourUser && !yourUser.isHost){
            return <p className="chattyMsg fade-transition">{chattyMessage}</p>
        }
    }

    function renderBanner(){
        if(props.allUsers){
            const yourUser = props.allUsers.find(u => u.uid === props.yourId);
            if(yourUser && (yourUser.iconId!==-1)){
                return (
                    <p className="lobbyRoomCodePrompt">
                        Join Code: <span className="lobbyRoomCode subTitlePulseElement">{props.roomCode}</span>
                    </p>
                )
            }
            else{
                return(
                    <>
                    <p className="gamePiecePrompt">Pick your character</p>
                    </>
                )
            }
        }
    }

    function renderBody(){
        if(props.allUsers){
            const yourUser = props.allUsers.find(u => u.uid === props.yourId);
            if(yourUser && (yourUser.iconId!==-1)){
                return (
                    <>
                    {renderUsers()}
                    {renderStartGameButton()}
                    </>
                )
            }
            else{
                if(yourUser && yourUser.isHost && !props.lvlsPicked){
                    return (
                        <>
                        {renderLvlSliders()}
                        </>
                    )
                }
                return(
                    <>
                    {renderIconButtons()}
                    </>
                )
            }
        }
    }
    let yourUser = null;
    if(props.allUsers){
        yourUser = props.allUsers.find(u => u.uid === props.yourId);
    }

    return (
            <DrEvent>
                <LobbyBanner>
                    {yourUser && yourUser.isHost && !props.lvlsPicked?null:renderBanner()}
                </LobbyBanner>
                <BodyContainer>
                    {renderBody()}
                </BodyContainer>
            </DrEvent>
    );
};

export default Lobby;