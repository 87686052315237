import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Page = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
`;

const Grid = styled.div`
height: 70vh;
width: 100vw;
display: grid;
justify-content: center;
align-content: center;
grid-auto-rows: 7%;
grid-auto-columns: 11%;
grid-gap: 2%;
grid-template-areas:
"i0 i1 i2 i3 i4 i5 i6"
"i7 i8 i9 i10 i11 i12 i13"
"i14 i15 i16 i17 i18 i19 i20"
"i21 i22 i23 i24 i25 i26 i27"
"i28 i29 i30 i31 i32 i33 i34"
"i35 i36 i37 i38 i39 i40 i41"
"i42 i43 i44 i45 i46 i47 i48"
"i49 i50 i51 i52 i53 i54 i55"
"i56 i57 i58 i59 i60 i61 i62"
"i63 i64 i65 i66 i67 i68 i69"
"i70 i71 i72 i73 i74 i75 i76";
/* hide the scrollbar */
::-webkit-scrollbar {
    display: none;
  }
`

const sheets = [
    ['🐸','🐊','🐍','🦎','🐛','🐉','🌳'
    ,'🌵','🌳','💚','🐉','🥦','🥬','🐛'
    ,'📗','🐍','🍏','🍀','🌳','🐍','📗'
    ,'🐊','🐸','🐛','🥝','🐊','🐸','🐊'
    ,'🌵','🦎','🐉','🐍','🥦','🌳','💚'
    ,'🐉','🍀','🍏','🌳','🍀','🍏','🐉'
    ,'🌳','🐸','📗','💚','🥦','🐛','🌳'
    ,'🌵','🌲','🐍','🥝','🥬','🦎','🐍'
    ,'🍀','🐍','🍏','💚','🐉','💚','🐊'
    ,'🦎','🥬','🌳','🐊','🐛','🥝','💚'
    ,'🐸','🐉','🥝','🍀','🥦','🐍','🌵'],

    ['🍅','🍓','🍒','🌶️','❤️','🏓','🚨'
    ,'🔴','💋','🌹','⛔️','🥊','🥩','🐞'
    ,'❤️','🐞','🍅','🌶️','🚒','💋','🍒'
    ,'🍒','🔴','🏓','❤️','⛔️','🌹','🍓'
    ,'🍅','🌶️','❤️','🚨','🍅','❤️','🍅'
    ,'💋','❤️','🌹','🍒','🌶️','🏓','🥩'
    ,'🩸','💋','🚒','🏓','🍓','🍒','🩸'
    ,'🍅','⛔️','🌶️','🐞','🚨','🍅','⛔️'
    ,'❤️','🍒','🍓','💋','🍒','🍓','🩸'
    ,'🚨','🩸','🍅','🥊','🌶️','🔴','🚒'
    ,'🍓','🚨','🔴','❤️','💋','🍓','🌶️'],

    ['🧢','🧿','💧','✈️','💦','🌊','🧊'
    ,'🐟','💙','🔷','🌐','💧','💙','🐟'
    ,'💦','💧','💦','🌊','🐳','✈️','🧿'
    ,'🧢','🧊','🐳','🧊','💦','🧊','🌐'
    ,'💧','✈️','🧢','💧','🐟','🧢','💧'
    ,'🧊','🧿','🧊','💦','💙','✈️','🧊'
    ,'🔷','🧢','💙','🐳','💧','💙','💦'
    ,'✈️','🌊','🧊','🌐','🧊','🌊','💧'
    ,'🐳','💦','🧿','💧','🐳','💦','✈️'
    ,'🧊','🐟','✈️','🐟','🧊','🐟','🐳'
    ,'🌐','💧','🌊','🧢','💙','✈️','🌊'],

    ['🤘','👌','🤟','✌️','✊','👍','☝️'
    ,'👉','👆','👎','✋','👍','✊','🤟'
    ,'👉','👍','👉','🤘','✊','✌️','👆'
    ,'👎','✋','👌','✋','👉','👍','✋'
    ,'👍','✌️','👎','👍','🤟','👎','👍'
    ,'✋','👆','✋','👉','✊','✌️','✋'
    ,'✊','👎','✊','👌','👍','✊','👉'
    ,'✌️','👍','✋','👍','✋','🤘','👍'
    ,'👌','👉','👍','👆','👌','👉','✌️'
    ,'✋','🤟','✌️','🤟','✋','🤟','👌'
    ,'👍','🤘','👍','👎','✊','✌️','🤘'],

    ['🔥','🎃','🧡','🍑','🏀','🥕','🎃'
    ,'🟠','🍑','📙','🦊','🥕','🏀','🧡'
    ,'🎃','🥕','🟠','🔥','🏀','🍑','🍑'
    ,'📙','🦊','🎃','🦊','🟠','🥕','🦊'
    ,'🥕','🍑','📙','🥕','🧡','📙','🥕'
    ,'🦊','🧡','🦊','🟠','🏀','🍑','🦊'
    ,'🏀','📙','🏀','🎃','🥕','🏀','🟠'
    ,'🍑','🥕','🦊','🥕','🦊','🔥','🥕'
    ,'🎃','🟠','🥕','🍑','🎃','🟠','🍑'
    ,'🦊','🧡','🍑','🧡','🦊','🧡','🎃'
    ,'🥕','🔥','🥕','📙','🏀','🍑','🔥']
] 


function IspyChallenge(props) {
    const [countdown, setCountdown] = useState(3);
    const [shotClock, setShotClock] = useState(0);
    const [activeBoard, setActiveBoard] = useState(false);
    const [imDone, setImDone] = useState(null);
    const [startInterval, setStartInterval] = useState(null);


    function handleBtnClicked(e){
        e.preventDefault();
        const choice = parseInt(e.currentTarget.id);
        if(imDone || !activeBoard || choice!==props.challengeJson.targetIndex){
            return
        }

        const endTime = Date.now();

        let res = (endTime - startInterval)/1000;

        sendMyTime(res)
        props.handleNextBtn(e)
    }

    function sendMyTime(res){
        setActiveBoard(false);
        setImDone(true)

        let payload = {};
        payload['userInput'] = res;
        payload['type'] = "input submitted"
        payload['displayType'] = "DESC"
        props.sendEvent("input submitted", payload)   

    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (countdown === 0) {
              clearInterval(interval);
              setActiveBoard(true);
              setStartInterval(Date.now());
              setShotClock(45);
            } else {
              setCountdown(countdown => countdown - 1);
            }
          }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, [countdown]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (shotClock === 1) {
              clearInterval(interval);
              if(activeBoard && !imDone){
                sendMyTime(45)
              }
            } else {
              setShotClock(shotClock => shotClock - 1);
            }
          }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, [shotClock]);

    function getTopPrompt(){
        if(countdown || activeBoard){
            return `Find the ${props.challengeJson['targetEmoji']}`
        }

        if(imDone==="safe"){
            return `Safe! Waiting...`
        }
        return `Find the ${props.challengeJson['targetEmoji']}`;
    }

    function renderGridBody(){
        if(countdown){
            return (
                <div style={{gridRow: "5", gridColumn: "1 / span 8",color: 'black', fontFamily: "var(--titleFont)", fontSize: "4vh", width:"100%"}}>{`Start in ${countdown}`}</div>
            )
        }
        if(activeBoard){
            let tiles = []
            for(let i=0;i<77;i++){
                const isTarget = i===parseInt(props.challengeJson.targetIndex)
                let borderColor = '#151515';
                if(isTarget && imDone){
                    borderColor = '#00FF32';
                }
                tiles.push(
                    <div id={i} style={{fontSize:'2.5vh', gridArea: "i"+i}} onClick={handleBtnClicked}>
                        {isTarget?
                        props.challengeJson.targetEmoji:
                        sheets[parseInt(props.challengeJson.sheetNum)][i]}
                    </div>
                )
            }
            return tiles
        }



        //If countdown
        //Countdown text, large: revealing in 3,2,1

        //If activeRound or results
        //Render emoji sheet
        //If eliminated: opacity = .4

        //if choiceMade
        //changeBackground of selection, red:green
        return null;
    }

    return (
    <Page className="challengeContainer"
    style={{ transform: props.flipped ? 'rotateY(180deg)' : '' }}>
        <div className='rulesTitleContainer' style={{animation: "none", textShadow: 'none', borderBottom: "none"}}>
        {`${getTopPrompt()}\n${shotClock<25 && shotClock>0?'('+shotClock+')':''}`}
        </div>
        <Grid>
            {renderGridBody()}
        </Grid>
    </Page>
    );
}

export default IspyChallenge;