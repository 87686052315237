import React, { Component, useEffect, useState } from 'react';
import DrContent from './DrContent';
class DrPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: window.innerHeight,
    };
    this.updateWindowHeight = this.updateWindowHeight.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowHeight);
  }

  updateWindowHeight() {
    this.setState({ windowHeight: window.innerHeight });
  }

  render() {
    return (
      <div style={{ height: this.state.windowHeight }}>
          {this.props.children}
      </div>
    );
  }
}

export default DrPage;