import React, {useState, useEffect} from 'react';
import QRCode from "react-qr-code";
function LobbyGrid(props){

const [isMobile, setIsMobile] = useState(window.innerHeight> window.innerWidth);
useEffect(()=>{
    setIsMobile(window.innerHeight > window.innerWidth)
},[window.innerHeight])

const playerColors =[
    "#00FFFF",
    "#FF0000",
    "#FFFF00",
    "#99FF00",
    "#339933",
    "#FF9450",
    "#990050",
    "#FF9900",
    "#9966FF",
    "#FFFFFF",
    "#FF99C3",
    "#0066CC",
    "white"
]
const pathToIconFile = "/icons/"
const iconSelectPaths = [
  "game piece-0.png",
  "game piece-01.png",
  "game piece-02.png",
  "game piece-03.png",
  "game piece-04.png",
  "game piece-05.png",
  "game piece-06.png",
  "game piece-07.png",
  "game piece-08.png",
  "game piece-09.png",
  "game piece-10.png",
  "game piece-11.png",
  "portrait4x.png"
];

function shortName(name){
    if(name.length>7){
        return name.slice(0,7)+".."
    }
    return name
}

function renderEmpty(){
    let empties = []
    for(let i=0;i<12-props.users.length;i++){
        empties.push(
            <div className="lobbyGridUserCard" />

        )
    }
    return empties
}

return(
<div className="lobbyContent">
    {isMobile?
<div className='lobbyIntro'>
    <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "40%", width: "40%" }}
                value={`https://drinkroyale.com?room=${props.roomCode}`}
                bgColor={'var(--baseColor)'}
                fgColor={"black"}
                viewBox={`0 0 256 256`}
              />

    <div className='lobbyInstructions'>
        <p>{isMobile?"Players scan to join or go to DrinkRoyale.com":"Players join at DrinkRoyale.com"}</p>
    </div>
</div>
:null}

<div className = "lobbyGrid">
    <>
    {props.users.map((player)=>(
        <div className="lobbyGridUserCard" style={{color: `${playerColors[player.iconId]}`}}>
        <div className='gridLobbyNameTag'>
            {shortName(player.name)}
        </div>
        <img  className='gridLobbyIcon'
              src={pathToIconFile + (player.iconId===-1 ?"portrait4x.png":iconSelectPaths[player.iconId])}
              alt={"playerIcon"}
        />
    </div>
    ))   
    }
    {
        renderEmpty()
    }
</>
</div>
</div>
)

}

export default LobbyGrid