import React, { useState, useEffect } from 'react';
import DrEvent from '../../display/DrEvent';
import Odometer from '../Odometer';

const iconPaths = [
    "game piece-0.png",
    "game piece-01.png",
    "game piece-02.png",
    "game piece-03.png",
    "game piece-04.png",
    "game piece-05.png",
    "game piece-06.png",
    "game piece-07.png",
    "game piece-08.png",
    "game piece-09.png",
    "game piece-10.png",
    "game piece-11.png",
    "portrait4x.png"
];


function RouletteChallenge(props) {
    const [voteTimer, setVoteTimer] = useState(10);
    const [allVotes, setAllVotes] = useState({});
    const [picking, setPicking] = useState(false)
    const [curSelection, setCurSelection] = useState(0);
    const [roundNum, setRoundNum] = useState(0);
    const [displayResults, setDisplayResults] = useState(false);
    const [loserPrompt, setLoserPrompt] = useState("")
    const [loserList, setLoserList] = useState([])
    const [fadeAway, setFadeAway] = useState(false);
    const [iLost, setILost] = useState(false)
    const [displayingFinal, setDisplayingFinal] = useState(false)
    

    useEffect(() => {
        const initialVotes = {};
        console.log(props.players)
        props.players.forEach((player) => {
            initialVotes[player.uid] = {
                id: player.uid,
                vote: "",
                iconId: player.iconId,
                name: player.name,
                eliminated: false,
            };
        });
        setAllVotes(initialVotes);
    }, []);

    useEffect(() => {
        if (props.socketData && props.socketData.type==="chal update") {
            updateVoter(
                props.socketData.userId,
                parseInt(props.socketData.userIcon),
                parseInt(props.socketData.userInput),
                props.socketData.name,
                false
            )
        }
      }, [props.socketData]);

      useEffect(() => {
        if(displayingFinal){
            return
        }
        const interval = setInterval(() => {
            if (voteTimer === 1) {
                setPicking(true)
            } else {
                setVoteTimer(voteTimer => voteTimer - 1);
            }
          }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, [voteTimer]);

    function updateMyVote(myVote){
        if(myVote===""){
            return
        }

        const player = props.players.find(u => u.uid === props.yourId)
        let payload = {}
        payload['userId'] = player.uid
        payload['userIcon'] = player.iconId
        payload['userInput'] = myVote
        payload['name'] = player.name
        payload['type'] = "chal update"
        props.sendEvent("chal action", payload);
    }

    function renderPrompt(){
        if(displayingFinal){
            return "Game Over"
        }
        if(loserPrompt){
            if(getRemainingPlayers().length>1){
                return "Next Round Starting Soon..."
            } else {
                 return ""
            }

        }
        if(picking){
            return "Eliminating 1 Color..."
        }
        if(voteTimer){
            return "Everyone Guess a Color"
        }        

    }

    useEffect(()=>{
        if(fadeAway){
            const losers = getLosers()
            losers.forEach((loser)=>{
                const iconElement = document.getElementById(`icon${loser.iconId}`)
                if(iconElement){
                    iconElement.style.animation = getFadeAnimation()
                }
            })
        }
    }, [fadeAway])

    useEffect(()=>{
        function startResults(){
            setTimeout(() => {
                const losers = getLosers()
                updateLosers(losers)
                displayLoserAlert(losers)
                if(getRemainingPlayers().length>1){
                    getReadyForNextRound()
                } else {
                    displayFinalResults();
                }
            }, 1000);

        }
        if(displayResults){
            startResults()
        }
    },[displayResults])

    useEffect(() => {
        function startPicking() {
            let ticks = 0;
            const MAX_TICKS = 12;
            let stopOnSelection = false;
            const order = props.order
            const interval = setInterval(() => {
                // Stop if we have already revealed the color
                ticks += 1;

                // Check if we should start looking for the target value
                if (ticks >= MAX_TICKS) {
                    stopOnSelection = true;
                }

                // Update curSelection to cycle between 0, 1, and 2
                const nextSelection = ticks % 3;
                setCurSelection(nextSelection);
                if (stopOnSelection && nextSelection === order[roundNum % order.length]) {
                    setFadeAway(true);
                    setTimeout(()=>{
                        setDisplayResults(true);
                        setFadeAway(false);
                    }, 1500)
                    clearInterval(interval);
                    return
                }
            }, 150);

            // Cleanup function to clear the interval when the component unmounts
            return () => {
                clearInterval(interval);
            };
        }
        if(picking){
            startPicking();
        }
    }, [picking]);

    function getReadyForNextRound() {
        console.log("NEXT ROUND");
        setTimeout(() => {
            setAllVotes(prevVotes => {
                const updatedVotes = {};
                Object.keys(prevVotes).forEach(playerId => {
                    updatedVotes[playerId] = {
                        ...prevVotes[playerId],
                        vote: ""  // Reset vote to an empty string
                    };
                });
                return updatedVotes;
            });
    
            setLoserPrompt("");
            setLoserList([]);
            setPicking(false);
            setDisplayResults(false);
            setVoteTimer(5);
            setRoundNum(roundNum => roundNum + 1);
        }, 3000);
    }

    function displayLoserAlert(losers){
        let prompt = `Players Eliminated:\n\nTake a Drink`
        let loserList = []
        if(!losers.length){
            prompt = 'Nobody got Eliminated!\n\n Next Round'
        } else {

            losers.forEach((player) =>{
                    loserList.push(
                        <div className="loser">
                            {player.name}
                        </div>
                    )
            })
        }
        setLoserList(loserList)
        setLoserPrompt(prompt)


    }

    function getFadeAnimation(){
        const animation = `driftAndFade0 1.25s linear .5s forwards`
        return animation;
    }

    function getLosers(){
        let losers = []
        Object.keys(allVotes).forEach((player)=>{
            if(!allVotes[player].eliminated && (parseInt(allVotes[player].vote)===curSelection || allVotes[player].vote==="")){
                losers.push(allVotes[player])
            }
        })
        return losers
    }

    function updateLosers(losers){
        console.log(losers)
        losers.forEach((loser)=>{
            updateVoter(
                loser.id,
                loser.iconId,
                "",
                loser.name,
                true
            )
            if(loser===props.yourId){
                setILost(true);
            }
        })

    }

    function getRemainingPlayers(){
        let winners = []
        Object.keys(allVotes).forEach((player)=>{
            if(!allVotes[player].eliminated){
                winners.push(allVotes[player])
            }
        })
        return winners
    }

    function updateVoter(id, icon, vote, name, eliminated) {
        if (!id || icon === undefined || name === undefined) return;
    
        setAllVotes(prevVotes => ({
            ...prevVotes,
            [id]: { id: id, vote: vote, iconId: icon, name: name, eliminated: eliminated }
        }));
    }

    function renderOdometer(){
        return <Odometer number={voteTimer}/>
    }

    function renderVoters(vote){
        let icons = [];
        Object.keys(allVotes).forEach((player)=>{
            if(parseInt(allVotes[player].vote) === vote){
                icons.push(
                <img src={`/icons/${iconPaths[parseInt(allVotes[player].iconId)]}`}
                className="rouletteIcon"
                id={`icon${allVotes[player].iconId}`}
                />)
            }
        })
        return icons;
    }



    function handleVote(e){
        e.preventDefault();
        if(picking || displayResults || iLost){
            return
        }
        const myVote = e.target.id
        updateMyVote(myVote)
    }

    function renderPageContent(){
        return <>
        <div className="roulettePrompt">
        {renderPrompt()}
        </div>
        <div className="rouletteOdometer"  style={{opacity: picking?0:1}}>
        {renderOdometer()}
        </div>

        <div className="rouletteContent">
            <div className={`rouletteColumn ${!picking || (picking && curSelection===0?'':'dull')}`} style={{background: "rgba(51, 255, 255, .3)"}}>
                <div className={`rouletteOption`} id="0" style={{background: "#33FFFF", animation: picking?"none": "animate-pulse-highlight-roulette 3s ease-in-out infinite"}} onClick={handleVote}></div>
                <div className="rouletteVoters">
                    {renderVoters(0)}
                </div>
            </div>
            <div className={`rouletteColumn ${!picking || (picking && curSelection===1?'':'dull')}`} style={{background: "rgba(255, 255, 102, .3)"}}>
                <div className={`rouletteOption`}  id="1" style={{background: "#FFFF66", animation: picking?"none": "animate-pulse-highlight-roulette 3s ease-in-out infinite"}}  onClick={handleVote}></div>
                <div className="rouletteVoters">
                    {renderVoters(1)}
                </div>
            </div>
            <div className={`rouletteColumn ${!picking || (picking && curSelection===2)?'':'dull'}`} style={{background: "rgba(255, 102, 153, .3)"}}>
                <div className={`rouletteOption`}  id="2" style={{background: "#FF6699", animation: picking?"none": "animate-pulse-highlight-roulette 3s ease-in-out infinite"}}  onClick={handleVote}></div>
                <div className="rouletteVoters">
                    {renderVoters(2)}
                </div>
            </div>
        </div>
        </>
    }
    function displayFinalResults(){
        setTimeout(() => {
            setDisplayingFinal(true)
        },3000)
    }

    function renderFinal(){
        const remainingPlayers = getRemainingPlayers()
        if(remainingPlayers.length===1){
            const winner = remainingPlayers[0].name
            return(<div className='loserAlert'>
                        {`${winner} wins!\n\nPick a player to take a 10 second chug`}
                </div>
            )
        } else {
            return(<div className='loserAlert'>
                {`Everyone got eliminated...\n\nEveryone take a sip i guess`}
            </div>
            )  
        }
    }
    function renderAlert(){
        return (<div className='loserAlert'>
            {loserPrompt}
            {loserList}
            </div>
        )
    }

    function renderButton(){
        if(props.yourId===props.turnPlayer){
            if(displayingFinal){
                return <button className='splashNavBtn roulette' onClick={props.handleClose}>END TURN</button>
            }
      }
    }


    window.scrollTo(0, 0);
    return (
        <>
        {loserPrompt?renderAlert():<></>}
        {displayingFinal?renderFinal():<></>}
    <DrEvent className="challengeContainer" style={{zIndex: 9999, backgroundColor: "pink"}}>
        {renderPageContent()}
        {renderButton()}
    </DrEvent>

    </>
    );
}

export default RouletteChallenge;