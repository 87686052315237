import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import '../Alert.css';
import styled from "styled-components";

gsap.registerPlugin(CSSRulePlugin);

const MailArt = styled.img`
  width: auto;
  height: 60%;
  position: relative;
  align-self: top;
`;

function Alert(props) {
  const flapRule = CSSRulePlugin.getRule('.envelope:before');
  const t1 = useRef(gsap.core.Timeline);
  const t2 = useRef(gsap.core.Timeline);


  useEffect(()=>{
    if(props.closeTriggered){
      closeAlert()
    }
  },[props.closeTriggered])
  useEffect(() => {

    const container = document.querySelector('.content');
    container.style.transform = 'translateX(-100vw)';
    // Fly in
    setTimeout(() => {
        container.style.transition = 'transform .750s';
        container.style.transform = 'translateX(0)';
    }, 200);
    t1.current = gsap.timeline({ paused: true });
    t2.current = gsap.timeline({ paused: true });

    t1.current
      .to(flapRule, {
        duration: 0.5,
        cssRule: {
          rotateX: 180,
        },
      })
      .set(flapRule, {
        cssRule: {
          zIndex: 10,
        },
      })
      .to('.letter', {
        translateY: -200,
        duration: 0.9,
        ease: 'back.inOut(1.5)',
      })
      .set('.letter', {
        zIndex: 40,
      })
      .to('.letter', {
        duration: 0.5,
        ease: 'back.out(0.4)',
        translateY: -5,
        translateZ: 200,
      });

  }, []);


  async function closeAlert() {
    if(!props.closeTriggered){
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    t1.current.reverse();
    t2.current.reverse();
    props.handleClose()
  }

  const openCard = () => {
    t1.current.play();
    t2.current.play();
  };

  const alertMsg = props.alertMsg.split("%%%")

  return (
    <div className="alertContainer">
      <MailArt className='mailArt' src={"./splashes/mail.png"} alt='mailArt' />
      <div className="content">
        <div className="envelope" onClick={openCard}></div>
        <div className="letter">
          <div className="body" onClick={(e) => {e.stopPropagation(); closeAlert();}}>
            <span className="close" >x</span>
            <div className="message">{alertMsg[0]}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
