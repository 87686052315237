import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Page = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
`;

function MoviesChallenge(props) {
    const [hintsUsed, setHintsUsed] = useState(0)
    const [drinkPrize, setDrinkPrize] = useState(10)
    const [solving, setSolving] = useState(true)

      
    useEffect(() => {
        if (hintsUsed === 2) {
            setSolving(true)
        }
    }, [hintsUsed]);


    function renderEmojis(){
        return <div className = 'movieEmojiWrapper'>
            {props.movieData.emojis[0] + props.movieData.emojis[1]+(props.movieData.emojis.length>2?props.movieData.emojis[2]:'')}
        </div>
    }

    function getPromptText(){
        if(solving && props.yourId === props.turnPlayer){
            return `◇ ${props.turnPlayerName} takes a drink if they can't name the movie\n\n◇ ${props.turnPlayerName}, just say your answer out loud. Other players have the answer`
        }
        if(solving && props.yourId !== props.turnPlayer){
            return `◇ Don't reveal the movie answer: ${props.movieData.movieName}\n\n◇ ${props.turnPlayerName} takes a drink if they can't name the movie`
        }
    }

    function renderPrompt(){
        return <div className='movieChalPrompt'>
                    {getPromptText()}
                </div>
    }

    function handleHint(e){
        e.preventDefault()
        let payload = {};
        payload['userInput'] = 'hint';
        payload['type'] = "chal update"
        props.sendEvent("chal action", payload)
    }

    function handleSolve(e){
        e.preventDefault()
        let payload = {};
        payload['userInput'] = 'solving';
        payload['type'] = "chal update"
        props.sendEvent("chal action", payload)
    }

    function renderButtons(){
        if(solving && props.yourId === props.turnPlayer){
            return <button className='splashNavBtn' onClick={props.handleClose}>END TURN</button>
        }
        if(!solving && props.yourId === props.turnPlayer){
           return <div className='movieChalButtons'>
            <button className='moviesChalButton' onClick={handleHint}>HINT</button>
            <button className='moviesChalButton' onClick={handleSolve}>SOLVE</button>
            </div>
        }
    }

    return (
        <Page className="challengeContainer" style={{ transform: props.flipped ? 'rotateY(180deg)' : '' }}>
            <div className='rulesTitleContainer' style={{textShadow: 'none', borderBottom: "none"}}>
                    {props.title}
            </div>
            {renderEmojis()}
            {renderPrompt()}
            {renderButtons()}
        </Page>
    );
}

export default MoviesChallenge;
