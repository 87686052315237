import React, { useState, useEffect } from 'react';
import '../Dice.css';


//TODO fix dice size based on isMobile
function DiceRoll(props) {
  const [stopAnimation, setStopAnimation] = useState(false);

  useEffect(() => {
    if (!props.rolling) {
      stopDice();
    } else {
      setStopAnimation(false);
    }
  }, [props.rolling]);

  async function stopDice() {
    const faces = ['front', 'back', 'left', 'right', 'top', 'bottom'];
    const randomFace = faces[Math.abs(props.diceVal)];
    const cube = document.querySelector('.cube');
    const face = cube.querySelector(`.${randomFace}`);
    await new Promise((resolve) => setTimeout(resolve, 250));
    setStopAnimation(true);
    const currentTransform = window.getComputedStyle(cube).getPropertyValue('transform');
    cube.style.transform = currentTransform;
    if(face){
    const endTransform = `translateZ(-30vw) rotateX(${face.dataset.rotateX}deg) rotateY(${face.dataset.rotateY}deg)`;

    cube.animate(
      [
        { transform: currentTransform },
        { transform: endTransform }
      ], {
        duration: 200,
        easing: 'ease',
        fill: 'forwards'
      }
    );
    }
    props.doneRolling();

    cube.style.color = "red !important";
    await new Promise((resolve) => setTimeout(resolve, 1500));

    const diceContainer = document.querySelector('.diceContainer');
    diceContainer.style.animation = 'fadeOut .2s linear 1';

    await new Promise((resolve) => setTimeout(resolve, 150));
    props.doneRevealing(Math.abs(props.diceVal)+1);
  }

  function getDiceStyle(num){
    if(props.diceVal<0){
      if(stopAnimation && num === (-1*props.diceVal)){
        return {animation: "animate-pulse-cursed-dice 1.25s forwards"}
      }
    }
    if(stopAnimation && num === props.diceVal){
      return {animation: "animate-pulse-dice 2s forwards"}
    }
    return {color:"white", borderColor:"white"}
  }

  return (
    <div className="diceContainer">
      <div className="cube-container">
        <div
          className="cube"
          style={{
            animation: stopAnimation ? 'none' : 'rotateCube .25s linear infinite'
          }}
        >
          <div className="face front" data-rotate-x="0" data-rotate-y="0" style={getDiceStyle(0)}>
            1
          </div>
          <div className="face back" data-rotate-x="0" data-rotate-y="180" style={getDiceStyle(1)}>
            2
          </div>
          <div className="face left" data-rotate-x="0" data-rotate-y="-90" style={getDiceStyle(3)}>
            4
          </div>
          <div className="face right" data-rotate-x="0" data-rotate-y="90" style={getDiceStyle(2)}>
            3
          </div>
          <div className="face top" data-rotate-x="90" data-rotate-y="0" style={getDiceStyle(5)}>
            6
          </div>
          <div className="face bottom" data-rotate-x="-90" data-rotate-y="0" style={getDiceStyle(4)}>
            5
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiceRoll;