import React, { useState, useEffect, useRef } from 'react';
import DrEvent from '../../display/DrEvent';
import { HuePicker } from 'react-color'


function ColorChallenge(props) {
  const [myColorValue, setMyColorValue] = useState('#00FFFF');

  const [shotClock, setShotClock] = useState(15);
  const handleChange = (color) => {
    setMyColorValue(color.hex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
        if (shotClock === 1) {
            clearInterval(interval);
            sendMyScore(colorSimilarityScore(myColorValue))
        } else {
          setShotClock(shotClock => shotClock - 1);
        }
      }, 1000);

  return () => {
    clearInterval(interval);
  };
}, [shotClock]);

function sendMyScore(res){
  let payload = {};
  payload['userInput'] = res;
  payload['type'] = "input submitted"
  payload['displayType'] = "ASC"
  payload['valLabel'] = '%'
  props.sendEvent("input submitted", payload)   
}

  function hexToRgb(hex) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return [r, g, b];
}

function colorSimilarityScore(testHex) {
    // Convert hex values to RGB
    let testRgb = hexToRgb(testHex);
    let targetRgb = hexToRgb(props.targetHex);

    // Calculate the Euclidean distance between the two colors in RGB space
    let distance = Math.sqrt(
        Math.pow(testRgb[0] - targetRgb[0], 2) +
        Math.pow(testRgb[1] - targetRgb[1], 2) +
        Math.pow(testRgb[2] - targetRgb[2], 2)
    );

    // The maximum possible distance in RGB space is the distance from black (0, 0, 0) to white (255, 255, 255)
    let maxDistance = Math.sqrt(Math.pow(255, 2) * 3);

    // Calculate similarity score as a percentage
    let similarityScore = ((maxDistance - distance) / maxDistance) * 100;

    return similarityScore.toFixed(2) // return the score rounded to two decimal places
}


      return <DrEvent className="challengeContainer">
        <div className='colorChalBanner'>
          {`Time left: ${shotClock}`}
        </div>
        <div className='colorChalCircleContainer'>
          <div className='colorWrapper'>
            <div className ='colorLabel'>Yours</div>
            <div className='colorChalCircle'
            style={{background: myColorValue}}></div>
          </div>
          <div className='colorWrapper'>
            <div className ='colorLabel'>Target</div>
            <div className='colorChalCircle' 
            style={{background:props.targetHex}}></div>
          </div>        
        </div> 

      <HuePicker  color={ myColorValue }
      onChange={ handleChange }/>
       </DrEvent>
}
export default ColorChallenge;