import styled from "styled-components";
import React from 'react';

const Page = styled.div`
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;
`;

function ResponseChallenge(props) {

    function sanitizeInput(input) {
        // Allow only alphanumeric characters, spaces, and some punctuation marks (.,!?)
        const regex = /[^a-zA-Z0-9\s.,!?]/g;
        const sanitizedInput = input.replace(regex, '');
        return sanitizeInput.length > 150 ?  "" : sanitizedInput;
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        let chalInput = document.querySelector(".chalInput")
        const userInput = chalInput.value.toLowerCase();
        const cleanInput = sanitizeInput(userInput);
        let payload = props.challengeJson;
        payload['userInput'] = cleanInput;
        payload['type'] = "chal update"
        payload['turnPage'] = true;
        props.sendEvent("chal action", payload)
    }

    return (
        <Page className="challengeContainer"
            style={{ transform: props.flipped ? 'rotateY(180deg)' : '' }}>
            <div className='chalTitleContainer'>
                {props.title}
            </div>

            <div className='subTitleContainer'>
                    {props.prompt}
                </div>
            <div className='responseChalContent'>

                {props.mainContent}
                
            </div>
            {(props.yourId===props.turnPlayer) ?
            <>
            <input type="text" className="chalInput" placeholder="Enter Text"/>
            <button className="splashNavBtn" onClick={handleSubmit}>SUBMIT</button>
            </>
            :null}
        </Page>
    )
}

export default ResponseChallenge;