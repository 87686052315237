import React, { useState, useEffect } from 'react';
import '../Splash.css';
const ProgressBar = ({ animationTime, textDelay, stopPercentage, filledText, isWinner }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setWidth(stopPercentage);
    }, animationTime);

    return () => {
      clearTimeout(timer);
    };
  }, [animationTime, stopPercentage]);

  return (
    <div className="progress-bar">
      <div
        className="progress-bar__fill"
        style={{ width: `${width}%`,
                background: isWinner?"#99ffcc":"white",
                boxShadow: isWinner? `0 0 10px #99ffcc`:`0 0 10px white`}}
      ></div>
      {width === stopPercentage && 
      <div className="progress-bar__text" 
      style={{color:isWinner?"#99ffcc":"white", 
      animation: `reveal 1s ${textDelay}s forwards`,
      textShadow: isWinner ? `0 0 15px #99ffcc`:`0 0 5px white`
      }}>{filledText}</div>}
    </div>
  );
};

export default ProgressBar;