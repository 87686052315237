import React, { Component } from 'react';

class DrEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPortrait: window.innerHeight > window.innerWidth
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      isPortrait: window.innerHeight > window.innerWidth
    });
  }

  render() {
    const { isPortrait } = this.state;

    return (
      <div className='drEvent' style={{width: isPortrait?'100%':'60%'}}>
        {this.props.children}
      </div>
    );
  }
}

export default DrEvent;