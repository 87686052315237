import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import DrEvent from '../../display/DrEvent';
const MAX_GUESSES = 5;


function WordleChallange(props) {
    const [myGuessesMade, setMyGuessesMade] = useState(0)
    const [shotClock, setShotClock] = useState(0);
    const [myGuesses, setMyGuesses] = useState([
        "", "", "", "", ""
    ])
    const [targetWord, setTargetWord] = useState("")
    const [startInterval, setStartInterval] = useState(null);
    const [countdown, setCountdown] = useState(3);


    useEffect(() => {
        const interval = setInterval(() => {
            if (countdown === 0) {
              clearInterval(interval);
              setStartInterval(Date.now());
              setShotClock(75);
            } else {
              setCountdown(countdown => countdown - 1);
            }
          }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, [countdown]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (shotClock === 1) {
                clearInterval(interval);
                setMyGuessesMade(MAX_GUESSES)
                sendMyTime(75)
            } else {
                if(!countdown){
                    setShotClock(shotClock => shotClock - 1);
                }
            }
          }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, [shotClock]);

    useEffect(()=>{
        setTargetWord(props.targetWord);
    },[])

    function gameOver(winner){
        if(winner){
            const endTime = Date.now();

            let res = (endTime - startInterval)/1000;
    
            sendMyTime(res)
        } else {
            sendMyTime(75)
        }
  
        props.handleNextBtn(null)
    }

    function sendMyTime(res){
        let payload = {};
        payload['userInput'] = res;
        payload['type'] = "input submitted"
        payload['displayType'] = "DESC"
        props.sendEvent("input submitted", payload)   
    }

    function renderBanner(){
        if(!shotClock){
            return `Start in ${countdown}`
        }

        return `${shotClock}`
    }

    function getLetterHighlight(guessNum, charIndex){
        if(guessNum>=myGuessesMade){
            return 'none'
        }
        const letter = myGuesses[guessNum][charIndex]
        let fillColor = 'darkgray'
        if(targetWord.includes(letter)){
            fillColor = 'orange'
            if(targetWord[charIndex] == letter){
                fillColor = 'lime'
            }
        }
        return fillColor
    }

    function getLetterBackground(guessNum){
        if(guessNum>=myGuessesMade){
            return 'none'
        }
        return "#151515"
    }

    function renderBody(){
            return<>
            <div className="wordleBanner">
                {renderBanner()}
            </div>
            <div className='wordleGrid'>
                {renderLetters()}
            </div>
                {renderKeyBoard()}
            </>
    }

    function renderLetters(){
        let result = []
        for(let i = 0; i < 5; i++){
            for(let j = 0; j < 4; j++){
                result.push(<div className='wordleLetter' style={{gridArea: `w${i}${j}`,
                backgroundColor: getLetterBackground(i),
                borderColor: getLetterHighlight(i,j), color: getLetterHighlight(i,j)}}>
                    {myGuesses[i] && myGuesses[i][j]?myGuesses[i][j]:' '}
                </div>
                )
            }
        }
        return result
    }

    function isDisabled(letter){
        if(myGuessesMade==MAX_GUESSES || !shotClock){
            return true
        }
        if(letter=='✅'){
            return myGuesses[myGuessesMade].length<4
        }
        for(let i=0; i<myGuessesMade;i++){
            if(myGuesses[i].includes(letter) && !targetWord.includes(letter)){
                return true
            }
        }
        return false
    }

    function clickKey(e){
        e.preventDefault();
        const letter = e.target.id
        if(isDisabled(letter)){
            return
        }
        let curGuess = myGuesses[myGuessesMade]
        const curGuesses = [...myGuesses];
        if(letter=='←'){
           const len = curGuess.length;
           if(len==0){
             return
           }
           curGuesses[myGuessesMade] = curGuess.slice(0, -1)
           setMyGuesses(curGuesses)
        } else if(letter=='✅'){
            if(curGuess.length==4){
                if(myGuessesMade+1==MAX_GUESSES){
                    gameOver(curGuess==targetWord)
                }
                setMyGuessesMade(myGuessesMade=>myGuessesMade+1)
                if(curGuess==targetWord){
                    gameOver(true)
                }
            }
        } else {
            if(curGuess.length<4){
                curGuesses[myGuessesMade] += letter
                setMyGuesses(curGuesses)
            }
        }
    }

    function renderKeyBoard(){
        const row1 = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"];
        const row2 = ["A", "S", "D", "F", "G", "H", "J", "K", "L"];
        const row3 = ["✅","Z", "X", "C", "V", "B", "N", "M","←"];
    
        const renderRow = (row, rowIndex) => {
            return row.map((key, index) => (
                <div className='wordleKey' key={`k${rowIndex}-${index}`} id={key}
                style={{gridArea: `k${rowIndex}-${index}`, width: key=='←'||key=='✅'?'13%':'',
                opacity: isDisabled(key)?.3:1}}
                onClick={clickKey}>
                    {key}
                </div>
            ));
        };
    
        return (
            <div className='wordleKeyboard'>
                <div className='keyboardRow'>{renderRow(row1, 1)}</div>
                <div className='keyboardRow'>{renderRow(row2, 2)}</div>
                <div className='keyboardRow'>{renderRow(row3, 3)}</div>
            </div>
        );
    }

    return (
    <DrEvent className="challengeContainer">
        {renderBody()}
    </DrEvent>
    );
}

export default WordleChallange;