import React, { useState, useEffect, useRef } from 'react';

const TurnSlider = ({ lobby }) => {
  const iconPaths = [
    "game piece-0.png",
    "game piece-01.png",
    "game piece-02.png",
    "game piece-03.png",
    "game piece-04.png",
    "game piece-05.png",
    "game piece-06.png",
    "game piece-07.png",
    "game piece-08.png",
    "game piece-09.png",
    "game piece-10.png",
    "game piece-11.png",
    "portrait4x.png"
];
const playerColors =[
    "#00FFFF",
    "#FF0000",
    "#FFFF00",
    "#99FF00",
    "#339933",
    "#FF9450",
    "#990050",
    "#FF9900",
    "#9966FF",
    "#FFFFFF",
    "#FF99C3",
    "#0066CC"
]

function findMiniGameSeatNum() {
    let activePlayers = lobby.players.filter(
        (player)=>player.connected);

    let highestSeatNum = -1;
    activePlayers.forEach(player =>{
        if (player['seatNum'] > highestSeatNum){
            highestSeatNum = player['seatNum']
        }
    })

    return highestSeatNum + 1;
  }

  function getPreviousIcon(){
    const prevSeat = getPreviousSeatNum()
    console.log(prevSeat)
    console.log(lobby.players)
    const playerInfo = lobby["players"].find(player => player["seatNum"] === prevSeat)
    return playerInfo['iconId']
  }

  function getPreviousSeatNum() {
    // Extract the seat numbers from the players list
    // let activePlayers = lobby.players.filter(
    //     (player)=>player.connected);  // Only include players who are connected
    const seatNums = lobby.players.map(player => player.seatNum); 
    // Sort seat numbers in ascending order
    seatNums.sort((a, b) => a - b);
    
    // Find the index of the current seat number
    const currentIndex = seatNums.indexOf(lobby.turnSeatNum);
    
    // If current seat is the first in the sorted list, return the last one
    if (currentIndex === 0) {
      return seatNums[seatNums.length - 1];
    }
    
    // Otherwise, return the previous seat number
    return seatNums[currentIndex - 1];
  }

function getNextSeatNum(offset){
    //let miniGameSeatNum = findMiniGameSeatNum()
    if(offset===0){
        return lobby.turnSeatNum
    }
    let newSeatNum = lobby.turnSeatNum + offset
    // if(newSeatNum===miniGameSeatNum){
    //     return miniGameSeatNum
    // }
    if(newSeatNum >= lobby.players.length){
        newSeatNum = newSeatNum % lobby.players.length; //TODO fix this mod
    }
    let player = lobby.players.find(u => u.seatNum === newSeatNum)


    while(!player.connected){
      newSeatNum+=1;
    //   if(newSeatNum===miniGameSeatNum){
    //     return miniGameSeatNum
    //   }
      if(newSeatNum>=lobby.players.length){
        newSeatNum = 0
      }
      player = lobby.players.find(u => u.seatNum === newSeatNum)
    }

    return newSeatNum;
}

    function getPlayerIconId(turnOffset){
        //let miniGameSeatNum = findMiniGameSeatNum()
        const offsetSeat = getNextSeatNum(turnOffset)
        // if(offsetSeat===miniGameSeatNum){
        //     return 12
        // }
        const playerInfo = lobby["players"].find(player => player["seatNum"] === offsetSeat)
        return playerInfo['iconId']
    }   
    const firstTurn = lobby.turnNum === 0
  return (
    <div className='turnSliderWrapper' style={{"--drWidth": window.innerHeight>window.innerWidth?"100vw":"49.5vw", 
    "--drMargin": window.innerHeight>window.innerWidth?"20vw":"0"}}>
    <div className='turnSliderLabel'>Coming up...</div>
    <div className="turnSliderContainer"
    style={{animation: firstTurn ?"none":"slideLeft 1s ease-in-out 1s forwards",
            transform: firstTurn?"translateX(-20vw)":"inherit"
    }}>
    <div className="turnSlider">
        <img className = 'turnSliderIcon dull'
        src={`./icons/${iconPaths[firstTurn?getPlayerIconId(5):getPreviousIcon()]}`}/>
    </div>
    <div className="turnSlider">
    <img className = {firstTurn?'turnSliderIcon':'turnSliderIcon dull firstSlider'}
        src={`./icons/${iconPaths[getPlayerIconId(0)]}`}/>
    </div>
    <div className={`turnSlider`}>
    <img className = 'turnSliderIcon dull'
        src={`./icons/${iconPaths[getPlayerIconId(1)]}`}/>
    </div>
    <div className={`turnSlider`}>
    <img className = 'turnSliderIcon dull'
        src={`./icons/${iconPaths[getPlayerIconId(2)]}`}/>
    </div>
    <div className={`turnSlider`}>
    <img className = 'turnSliderIcon dull'
        src={`./icons/${iconPaths[getPlayerIconId(3)]}`}/>
    </div>
    <div className={`turnSlider`}>
    <img className = 'turnSliderIcon dull'
        src={`./icons/${iconPaths[getPlayerIconId(4)]}`}/>
    </div>
    </div>
    </div>
  );
};

export default TurnSlider;