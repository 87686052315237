import React, {useState, useEffect, useRef, useMemo} from 'react';
import styled from "styled-components";
import DiceRoll from './DiceRoll.js';
import Splash from './EventCard.js';
import Results from './ResultCard.js';
import Alert from './Alert.js'
import Leaderboard from './Leaderboard.js';

import { stageTurnData } from './TurnHandler.js';

const isMobile = window.innerWidth < window.innerHeight;
let bannerH = "15%";
let boardH = "70%"
let tileH = "10%";
let tileW = "6%";
let controlsH = "13%";
let imageW = "auto";
let imageH = "100%";
if(isMobile){
    bannerH = "4%";
    boardH = "96%";
    controlsH = "10%"
    tileH = "auto";
    tileW ="10vw";
    imageW = "100%";
    imageH = "auto";
}


const Page = styled.div`
    height: ${window.innerHeight}px;
    width: 100vw;
    overflow-x:hidden;
    overflow-y:hidden;
    background-color: var(--offBlack);
`;

const BodyContainer = styled.div`
    height: ${boardH};
    width: 100%;
    background-color: var(--offBlack);
    color: white;
    overflow: auto;
    display: flex;
    ::-webkit-scrollbar {
        display: none;
      }
    border: 2px solid #99ffcc;
    box-sizing: border-box;
    justify-content: center;
    padding: 2vh;
    transition: border-color 1s linear;
`;

/*
"a0 . a12 a13 a14"
"a1 . a11 . a15"
"a2 . a10 . a16"
"a3 . a9 . a17"
"a4 . a8 . a18"
"a5 a6 a7 . a19";
*/
const MiniBoard = styled.div`
height: 100%;
width: 100%;
display: grid;
z-index: 0;
transition: opacity .5s ease-in-out;
justify-content: center;
align-content: center;
grid-auto-rows: auto;
grid-auto-columns: 18%;
grid-template-areas:
"a0 . a16 a15 a14"
"a1 . a17 . a13"
"a2 . a18 . a12"
"a3 . a19 . a11"
"a4 . . . a10"
"a5 a6 a7 a8 a9";
/* hide the scrollbar */
::-webkit-scrollbar {
    display: none;
  }
`
const BigBoard = styled.div`
height: 100%;
width: 100%;
display: grid;
z-index: 0;
transition: opacity .5s ease-in-out;
justify-content: center;
grid-auto-rows: auto;
grid-auto-columns: 11.5vw;
grid-template-areas:
"a0 a1 a2 a3 a4 a5"
". . . . . a6"
"a12 a11 a10 a9 a8 a7"
"a13 . . . . ."
"a14 a15 a16 a17 a18 a19"
". . . . . a20"
"a26 a25 a24 a23 a22 a21"
"a27 . . . . ."
"a28 a29 a30 a31 a32 a33";

/* hide the scrollbar */
::-webkit-scrollbar {
    display: none;
  }
`

/*
"a0 . a24 a23 a22 a21"
"a1 . a25 . . a20"
"a2 . a26 . . a19"
"a3 . a27 . . a18"
"a4 . a28 . . a17"
"a5 . a29 . . a16"
"a6 . a30 a31 . a15"
"a7 . . . . a14"
"a8 a9 a10 a11 a12 a13";

"a0 a1 a2 a3 a4"
". . . . a5"
"a18 a19 a20 . a6"
"a17 . a21 . a7"
"a16 . a22 . a8"
"a15 . . . a9"
"a14 a13 a12 a11 a10"

"a0 a1 a2 a3 a4 a5"
". . . . . a6"
"a28 . . . . a7"
"a27 a26 a25 a24 . a8"
". . . a23 . a9"
"a19 a20 a21 a22 . a10"
"a18 . . . . a11"
"a17 a16 a15 a14 a13 a12";

"a0 . a32 a31 a30 a29 a28 a27 a26"
"a1 . a33 . . . . . a25"
"a2 . a34 . a50 a49 a48 . a24"
"a3 . a35 . a51 . a47 . a23"
"a4 . a36 . a52 . a46 . a22"
"a5 . a37 . a53 . a45 . a21"
"a6 . a38 . . . a44 . a20"
"a7 . a39 a40 a41 a42 a43 . a19"
"a8 . . . . . . . a18"
"a9 a10 a11 a12 a13 a14 a15 a16 a17";
*/

const Tile = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  transition: .25s ease-out;
`;

const VerticalTile = styled.div`
transform: skew(0deg);
height: 100%;
width: 100%;
border: 3px solid white;
`;

const GapTile = styled.div`
transform: skew(0deg);
    height: 100%;
    width: 100%;
    background:var(--offBlack);
`

const Banner = styled.div`
    height: ${bannerH};
    width: 100vw;
    justify-content: center;
    align-items:center;
    background-color: var(--offBlack);
    z-index: 10;
`;

const ControlsContainer = styled.div`
    height: ${controlsH};
    width: 100vw;
    justify-content: center;
    align-items:center;
    background-color: var(--offBlack);
    z-index:999;
    padding-top:2vh;
    position: absolute;
    
`;

const Header = styled.div`
    display: flex;
    justify-content: center;
    height: 20%;
    width: 100%;
    background-color: var(--offBlack);
    align-items:top;

`

const BannerText = styled.div`
position: absolute;
top: 0px;
justify-content: center;
`
const MainTitle = styled.div`
    flex: 1;
    display: flex;
    justify-content: left;
    margin-left: 2vw;
`

const RoomCode = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-right: 5vw;
`

const Artwork = styled.img`
  width: ${imageW};
  height: ${imageH};
`;

const iconPaths = [
    "game piece-0.png",
    "game piece-01.png",
    "game piece-02.png",
    "game piece-03.png",
    "game piece-04.png",
    "game piece-05.png",
    "game piece-06.png",
    "game piece-07.png",
    "game piece-08.png",
    "game piece-09.png",
    "game piece-10.png",
    "game piece-11.png",
    "portrait4x.png"
];
const playerColors =[
    "#00FFFF",
    "#FF0000",
    "#FFFF00",
    "#99FF00",
    "#339933",
    "#FF9450",
    "#990050",
    "#FF9900",
    "#9966FF",
    "#FFFFFF",
    "#FF99C3",
    "#0066CC"
]

const playerColorAlphas =[
    "rgb(255, 253, 32, .08)",//bee
    "rgb(221, 4, 38, .1)",//bull
    "rgb(53, 161, 255, .15)",//robot
    "rgb(255, 255, 255, .08)",//bird
    "rgb(0, 255, 50, .1)",//snake
    "rgb(196, 154, 108, .08)",//cowboy
    "rgb(236, 0, 140, .1)",//worm
    "rgb(247, 148, 29, .1)",//chess
    "rgb(209, 211, 212, .1)",//forklift
    "rgb(0, 234, 244, .1)",//octopus
    "rgb(0, 161, 75, .15)",//lime
    "rgb(198, 106, 255, .1)"//eggplant
]


function GameBoard(props){
    const [rolling, setRolling] = useState(false);
    const [timeToRoll,setTimeToRoll] = useState(false);
    const [revealingDice, setRevealingDice] = useState(false);
    const [bannerText, setBannerText] = useState("Start Game"); 
    const [tileMap, setTileMap] = useState(null);
    const [displayingSplash, setDisplayingSplash] = useState(false);
    const [closeSplashAnimation, setCloseSplashAnimation] = useState(false);
    const [closeResultsAnimation, setCloseResultsAnimation] = useState(false);
    const [displayingResults, setDisplayingResults] = useState(false);
    const [displayingAlert, setDisplayingAlert] = useState(false);
    const [playerPosition, setPlayerPosition] = useState(0);
    const [movingPlayer, setMovingPlayer] = useState(0);
    const [turnPlayer, setTurnPlayer] = useState(0);
    const [turnPlayerName, setTurnPlayerName] = useState("");
    const [alertQueued, setAlertQueued] = useState("");
    const [timeToShowAlert, setTimeToShowAlert] = useState(true);
    const [tileDims, setTileDims] = useState({})
    const [playerDim, setPlayerDim] = useState(0);


    function replaceTiles(tileSet, replacementTiles){
        for(let i=0; i<replacementTiles.length;i++){
            let tile = tileSet.find(t => t.varTile === i);
            let tileText = replacementTiles[i]
            switch(tileText){
                case "Take Drinks":
                    tile.text = tileText;
                    tile.categoryId = '1';
                    break;
                case "Give Drinks":
                    tile.text = tileText;
                    tile.categoryId = '2';
                    break;
                case "Drink If":
                    tile.text = tileText;
                    tile.categoryId = '3';
                    break;
                case "Solo":
                    tile.text = tileText;
                    tile.categoryId = '4';
                    break;
                case "All Play":
                    tile.text = tileText;
                    tile.categoryId = '5';
                    break;
                case "Duel":
                    tile.text = tileText;
                    tile.categoryId = '6';
                    break;
                case "Rule":
                    tile.text = tileText;
                    tile.categoryId = '7';
                    break;
                case "Hot Seat":
                    tile.text = tileText;
                    tile.categoryId = '8';
                    break;
                case "Dare":
                    tile.text = tileText;
                    tile.categoryId = '9';
                    break;
                case "Chance":
                    tile.text = tileText;
                    tile.categoryId = '10';
                    break;
                case "Bad Luck":
                    tile.text = tileText;
                    tile.categoryId = '11';
                    break;
            }
        }
        return tileSet;
    }

    function personalizeBoard(tiles){
        let sLvl = props.lobby.sLvl
        let dLvl = props.lobby.dLvl
        let miniBoard = props.lobby.miniBoard;
        let replacementTiles;
        if(miniBoard){
            replacementTiles = ["","","","","","","",""]
            if(sLvl === 0 && dLvl === 1){
                replacementTiles = ["Take Drinks","","","","","","","Give Drinks"]
            }
            if(sLvl === 0 && dLvl === 2){
                replacementTiles = ["","","","","Give Drinks","","","Give Drinks"]
            }
            if(sLvl === 1 && dLvl === 0){
                replacementTiles = ["","","","Dare","","","",""]
            }
            if(sLvl === 1 && dLvl === 2){
                replacementTiles = ["Take Drinks","","","","Give Drinks","","","Give Drinks"]
            }
            if(sLvl === 2 && dLvl === 0){
                replacementTiles = ["","","","Dare","Solo","Dare","Hot Seat",""]
            }
            if(sLvl === 2 && dLvl === 1){
                replacementTiles = ["","","","Dare","Solo","Drink If","Hot Seat",""]
            }
            if(sLvl === 2 && dLvl === 2){
                replacementTiles = ["","","","Dare","Give Drinks","Drink If","Hot Seat","Solo"]
            }
        } else {
            replacementTiles = ["","","","","","","","","","","","","","","",""]
            if(sLvl === 0 && dLvl === 1){
                replacementTiles = ["","","","","","","Take Drinks","","","","","Take Drinks","","","",""]
            }
            if(sLvl === 0 && dLvl === 2){
                replacementTiles = ["","","","","","","Take Drinks","","","","","Take Drinks","","","",""]
            }
            if(sLvl === 1 && dLvl === 0){
                replacementTiles = ["Hot Seat","","","","","","","Dare","","","","","","","",""]
            }
            if(sLvl === 1 && dLvl === 2){
                replacementTiles = ["","","","","","","","Dare","Take Drinks","","Take Drinks","","","","",""]
            }
            if(sLvl === 2 && dLvl === 0){
                replacementTiles = ["","Hot Seat","","","Dare","Drink If","","Dare","","","Drink If","","","","",""]
            }
            if(sLvl === 2 && dLvl === 1){
                replacementTiles = ["","Hot Seat","","","","","","Dare","","","Drink If","","","","",""]
            }
            if(sLvl === 2 && dLvl === 2){
                replacementTiles = ["","","","","Take Drinks","","","Dare","","","Take Drinks","","","","",""]
            }
        }
        return replaceTiles(tiles, replacementTiles)
    }

    //Triggers on mount
    useEffect(()=>{

        setTileMap(props.tileMap)
        if(props.lobby.turnPlayer === props.yourId && !props.showLeaders){
            setTimeToRoll(true);
        } else {
            setTimeToRoll(false);
        }
        const vwWidth = window.innerWidth / 100; // calculate 1vw in pixels
        const multiplier = props.lobby.miniBoard?26:16
        const playerSize = multiplier * vwWidth; 
        setPlayerDim(playerSize);

        // Function to update window dimensions
        const updateWindowDimensions = () => {
            //Resize height and width of components
        };
  
      // Add event listener to update dimensions when window is resized
      window.addEventListener('resize', updateWindowDimensions);
    },[])

    const tiles = useMemo(() => {

        const renderedTiles = [];
            if(tileMap){
                //console.log(tileMap['miniBoard'].length)
                //console.log(tileMap['bigBoard'].length)
            const tileSet = props.lobby.miniBoard?tileMap['miniBoard']:tileMap['bigBoard']
            const personalizedTiles = personalizeBoard(tileSet)
            for (let j = 0; j < (personalizedTiles.length); j++) {
                renderedTiles.push(renderTile(personalizedTiles[j], j));
            }
            return renderedTiles;
        }
    }, [tileMap]);

    useEffect(()=>{
        socketDataRef.current = props.socketData
        if(props.socketData){
            handleSocketData(props.socketData);
        }
    },[props.socketData])

    const prevRuleRef = useRef(props.lobby.previousRule)
    const socketDataRef = useRef(props.socketData)
    useEffect(()=>{
        prevRuleRef.current = props.lobby.previousRule
    },[props.lobby.previousRule])

    useEffect(()=>{
        if(props.lobby){
            setTurnPlayer(props.lobby.turnPlayer);
            if(getTurnPlayer()){
                setTurnPlayerName(getTurnPlayer().name)
            }
        }
    },[props.lobby])

    useEffect(()=>{
        if(props.playersSwapped){
            console.log("Moving player updated")
            setMovingPlayer(1);
        }
    },[props.playersSwapped])


    //Triggers landing tile
    useEffect(()=>{
        if(props.tileData){
            let pos = props.tileData['pos']
            let val = props.tileData['val']
            let uid = props.tileData['uid']
            setTimeToShowAlert(false);
            if(props.tileData.alertPayload && uid === props.yourId){
                props.sendEvent("send alert", props.tileData.alertPayload)
            }
            move(uid, pos, val);
        }
    },[props.tileData])


    //Updates banner messages
    useEffect(()=>{
        setBannerText(props.bannerMsg)
    }, [props.bannerMsg]);


    useEffect(()=>{
        if(props.alert){
            setAlertQueued(props.alert)
        }
    }, [props.alert])

    //Control function for when dice are displayed on players screen
    const handleStartRoll = () => {
        if(displayingAlert){
            handleDoneAlert()
        }
        setRolling(true);
        setTimeToShowAlert(false);
    }

    const handleRollBtnPressed = (e) =>{
        e.preventDefault();

        let randomNumber = Math.floor(Math.random() * 6);
        let playerRule = getTurnPlayer().turnRule
        if(playerRule && playerRule.rule==="Cursed Dice"){
            randomNumber = Math.floor(Math.random() * 4)+1;
            randomNumber=randomNumber*-1;
        }
        props.sendEvent("roll started", randomNumber)
    }

    //Triggers reveal of number to player
    const handleStopRoll = () => {
        setRolling(false);
        setRevealingDice(true);
    }

    const handleDoneAlert = () =>{
        setAlertQueued(null);
        setDisplayingAlert(false);
        setTimeToShowAlert(false);
    }

    //Splash and Results are the only two exit points of an event card
    const handleDoneSplash = () =>{
        if(alertQueued){
            setDisplayingAlert(true);
        }
        setDisplayingSplash(false);
        setCloseSplashAnimation(false);
        matchBorderToPlayer()
        window.scrollTo(0, 0);
    }

    const handleDoneResults = () =>{
        if(alertQueued){
            setDisplayingAlert(true);
        }
        setDisplayingResults(false);
        setCloseResultsAnimation(false);
        matchBorderToPlayer()
    }

    function getTurnPlayerColor(){
        const tp = props.lobby.players.find(u => u.uid === props.lobby.turnPlayer)
        return playerColors[tp.iconId]
    }

    function matchBorderToPlayer(){
        let themeColor = getTurnPlayerColor();
        const board = document.querySelector('.gameBoardBody');
        board.style.borderColor = themeColor;
        const banner = document.querySelector('.bannerText')
        banner.style.textShadow = `0 0 15px ${themeColor}`;
        banner.style.color = themeColor;
    }


    function getPlayer(id){
        return props.lobby.players.find(u => u.uid === id)
    }

    function getTurnPlayer(){
        return props.lobby.players.find(u => u.uid === props.lobby.turnPlayer)
    }

    //Hides the roll button
    function doneRolling(){
        setTimeToRoll(false);
    }

    function handleSocketData(payload){
        switch(payload.type){
            case 'roll triggered':
                handleStartRoll()
                break;
            case 'vote results':
                if(!props.tileData.hasOwnProperty("noVoteResult")){
                    setDisplayingSplash(false);
                    setDisplayingResults(true);
                }
                break;
            case 'input results':
                if(!props.tileData.hasOwnProperty("noLeaderboard")){
                    setDisplayingSplash(false);
                    setDisplayingResults(true);
                }
                break;
            case 'end turn':
                const resultPage = document.querySelector('.resultPage');
                const splashPage = document.querySelector('.splashContainer');
                const container = resultPage || splashPage;
                if(container){
                    container.style.opacity = 0;
                    setTimeout(() => {
                        setDisplayingSplash(false);
                        setDisplayingResults(false);
                    },500);
                } else {
                    setDisplayingSplash(false);
                    setDisplayingResults(false);
                }
                const turnRule = getTurnPlayer().turnRule
                if(turnRule && turnRule.turnNum !== props.lobby.turnNum){
                        const ruleName =  getTurnPlayer().turnRule.rule
                        if(ruleName !== "Cursed Dice"){
                            setAlertQueued(`${ruleName} ended for ${getTurnPlayer().name}`)

                            getTurnPlayer().turnRule = null;
                        }
                    }


                if(props.lobby.turnPlayer === props.yourId){
                    setTimeToRoll(true);
                } else {
                    setTimeToRoll(false);
                    //setAlertQueued("Waiting for "+turnPlayerName+" to start...")
                }
                setTimeToShowAlert(true);
                if(alertQueued){
                    setDisplayingAlert(true);
                }
                break;
        }
    }

    //Pushes out data from dice roll
    function doneRevealing(val){
        let dev = false;
        setRevealingDice(false);
        let backwards = props.socketData.diceVal < 0
        let turnRule = getTurnPlayer().turnRule
        if(turnRule && turnRule.rule === "Cursed Dice"){
            getTurnPlayer().turnRule = null;
        }
        if(props.lobby.turnPlayer === props.yourId){
            stageTurnData(props, dev?-6:backwards?props.socketData.diceVal-1:props.socketData.diceVal+1);
        }
    }


    async function move(uid, position, numSpaces){
        if(displayingAlert){
            handleDoneAlert()
        }
        if(socketDataRef.current && socketDataRef.current.type === 'end turn'){
            //In case we lose a player right after they start moving
            //Normally this should have turn data in socketData
            return
        }

        setMovingPlayer(uid);
        setPlayerPosition(position);


        await new Promise((resolve) => setTimeout(resolve, 200));
        const duration = 200; // Animation duration in milliseconds
        if(numSpaces<0){
            for (let i = 0; i > numSpaces; i--) {
                await new Promise((resolve) => {
                  setTimeout(() => {
                    setPlayerPosition((prevPosition) =>(prevPosition - 1))
                    resolve()
                  }, duration);
                });
              }  
        } else {
            for (let i = 0; i < numSpaces; i++) {
                await new Promise((resolve) => {
                  setTimeout(() => {
                    setPlayerPosition((prevPosition) => (prevPosition + 1))
                    resolve();
                  }, duration);
                });
              }
        }

        await new Promise((resolve) => setTimeout(resolve, 750));
        doneMoving();
    }

    function doneMoving(){
        if(socketDataRef.current && socketDataRef.current.type === 'end turn'){
            //In case we lose a player right after they start moving
            //Normally this should have turn data in socketData
            return
        }

        let currentPosition = props.tileData.pos + props.tileData.val
        const landingTile = document.querySelector(`[data-tile="a${currentPosition}"]`)
        setTileDims(landingTile.getBoundingClientRect());
        setDisplayingSplash(true);
        setMovingPlayer(null);
    }

    function getMovingPlayerStyles() {
        const tile = document.querySelector(`[data-tile="a${playerPosition}"]`);
        if (!tile) return {};
      
        const { top, left, width, height } = tile.getBoundingClientRect();
        return {
          position: "absolute",
          top: height/2 + top - playerDim / 2 + "px", // position in the center of the tile
          left: left + width / 2 - playerDim / 2 + "px",
          width: playerDim + "px",
          height: playerDim + "px",
          transition: "all 200ms ease-in-out",
        };
      }

      function getIdlePlayerStyles(pos) {
        
        const tile = document.querySelector(`[data-tile="a${pos}"]`);
        if (!tile || pos===0){
            return {opacity: 0};
        } 
      
        const { top, left, width, height } = tile.getBoundingClientRect();
      

        return {
          position: "absolute",
          top:  height/2 +top - playerDim / 2 + "px", // position in the center of the tile
          left: left + width / 2 - playerDim / 2 + "px",
          width: playerDim + "px",
          height: playerDim + "px"
        };
      }


/* Used to go between /bannerText and /header
            <RoomCode>
                {props.lobby.currentRule ? <div className ="ruleDisplay">
                {
                props.useOldRule ? 
                prevRuleRef.current ?   <>Group Rule: <br/>{prevRuleRef.current}</>:<></>
                : <>Group Rule: <br/>{props.lobby.currentRule}</>
                }
                </div> : <></>}
            </RoomCode>

*/

    function renderHeader(props){
        return(
            <Header>
            <BannerText>
                <p className="bannerText"  
                key={props.bannerMsg}
                style={{ color: getTurnPlayerColor(), textShadow:`0 0 15px ${getTurnPlayerColor()}`}}>{props.bannerMsg}</p>
            </BannerText>

            </Header>
        )

    }

    function renderTile(tile, placement){
        return(
            <Tile  data-tile={`a${placement}`} style ={{gridArea:"a"+placement, opacity:'65%'}}>
                <Artwork src={`/thumbnails/thumb_${tile.text}.png`}/> 
            </Tile>
        );        
    }

    const movingPiece = useMemo(() => {
        if (movingPlayer && movingPlayer!==1) {
          let iconId = getPlayer(movingPlayer).iconId;
          let path = iconPaths[iconId];
          return (
            <Artwork
              src={`./icons/${path}`}
              style={{ ...getMovingPlayerStyles() }}
              key={movingPlayer} // Adding a unique key to the Artwork component
            />
          );
        }
    }, [movingPlayer, playerPosition]);
    
    const idlePieces = useMemo(() => {
          let result = [];
          props.lobby.players.forEach((player)=>{
            let iconId = player.iconId;
            if (iconId && (player.uid !== movingPlayer)) {
              let isConnected = player.connected;
                if(player.position){
                    let iconStyle = {
                    ...getIdlePlayerStyles(player.position),
                    opacity: isConnected ? 1 : 0.4,
                    };
                    result.push(
                    <Artwork
                        src={`/icons/${iconPaths[iconId]}`}
                        style={iconStyle}
                        key={player.uid}
                    />
                    );
                }
            }
        })
          return result;
        }, [props.lobby.players, movingPlayer]);

    function renderBody(){
        return <> 
        {props.lobby.miniBoard?
        <MiniBoard className="boardClass" style={{opacity: (timeToRoll||rolling||displayingAlert)?"40%":"100%"}}>
                        <ul class="bubbles" style={{"--playerColor": getTurnPlayerColor()}}>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        <>
        {movingPiece}
        {tiles?tiles:null}
        {idlePieces}
        </>
        </MiniBoard>:
            <BigBoard className="boardClass" style={{opacity: (timeToRoll||rolling||displayingAlert)?"30%":"100%"}}>
                        <ul class="bubbles" style={{"--playerColor": getTurnPlayerColor()}}>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        <>
        {movingPiece}
        {tiles?tiles:null}
        {idlePieces}
        </>
        </BigBoard>}
        {renderForegound()}
        </>
    }

    function renderForegound(){
        if(props.showLeaders){
            //                {alertQueued?renderAlert(alertQueued):null}
            return<>
            {alertQueued?renderAlert(alertQueued):null}
                <Leaderboard
                lobby = {props.lobby}
                yourId = {props.yourId}
                turnPlayer = {turnPlayer}
                sendEvent = {props.sendEvent}
                showLeaders={props.showLeaders}
                library = {props.library}
                dLvl={props.dLvl}
                />
            </>

        }
    else if (displayingAlert && alertQueued){
            return <>                
            {renderAlert(alertQueued)}
            </>
        } else if(rolling||revealingDice){
            if(rolling && !revealingDice){
                setTimeout(()=>{
                    handleStopRoll()
                },1000)
            }
            return (
                <>
            <DiceRoll
                rolling={rolling}
                doneRolling ={doneRolling}
                doneRevealing={doneRevealing}
                diceVal={props.socketData.diceVal}
            />
                </>
            )

        }  else if(displayingResults){
            return (
                <Results
                    socketData = {props.socketData} 
                    handleClose = {handleDoneResults}
                    sendEvent = {props.sendEvent}  
                    turnPlayer = {turnPlayer}
                    yourId = {props.yourId} 
                    closeTriggered = {closeResultsAnimation}
                    prize = {props.tileData.prize}   
                    lobby = {props.lobby}    
                    turnPlayerName = {turnPlayerName}         
                />
            )
        }else if(displayingSplash){                
                return (
                    <>
                <Splash
                    tileData = {props.tileData}
                    handleClose = {handleDoneSplash} 
                    sendEvent = {props.sendEvent}  
                    closeTriggered = {closeSplashAnimation}
                    turnPlayer = {turnPlayer}
                    turnPlayerName = {turnPlayerName}
                    yourId = {props.yourId}
                    challengeData = {props.challengeData}
                    socketData = {props.socketData}    
                    tileDims = {tileDims}
                    prevRule = {prevRuleRef.current}
                    lifeRaftPrompts = {props.library['lifeRaft']}
                    splashes = {props.splashes}
                    players ={props.lobby.players}
                    board = {true}
                />
                </>
                )
        } else if (alertQueued && timeToShowAlert){
            setDisplayingAlert(true)
        }  else {
            return(
                <></>
            )
        }
    }

    function renderAlert(alertQueued){

        const alertMsg = alertQueued.split("%%%")[0]
        return(
            <button className="alertBtn" onClick={handleDoneAlert} > {alertMsg} </button>
        ) 
    }
    

    function renderControls(){
        if(timeToRoll && (!rolling && !revealingDice && !displayingAlert)){
            return(
                <button className="rollBtn" onClick={handleRollBtnPressed} > Roll </button>
            )
        } 
    }

    return (
        <div className='basicPage' style={{"--viewHeight": window.innerHeight}}>
                <Banner>
                    {renderHeader(props)}
                </Banner>
                <BodyContainer className='gameBoardBody' style={{borderColor: getTurnPlayerColor()}}> 
                    {renderBody()}
                    {renderControls()}
                </BodyContainer>
        </div>
    );
}

export default GameBoard;

/*
"a37 a38 a39 a40 a41 a42 a43 . a11"
"a36 . . . . . a44 . a12"
"a35 . a59 a60 a61 . a45 . a13"
"a34 . a58 . a62 . a46 . a14"
"a33 . a57 . a63 . a47 . a15"
"a32 . a56 . a64 . a48 . a16"
"a31 . a55 . . . a49 . a17"
"a30 . a54 a53 a52 a51 a50 . a18"
"a29 . . . . . . . a19"
"a28 a27 a26 a25 a24 a23 a22 a21 a20";
*/

/*
"a1 a2 a3 a4 a5 a6 a7 a8 a9 a10"
". . . . . . . . . a11"
"a21 a20 a19 a18 a17 a16 a15 a14 a13 a12"
"a22 . . . . . . . . ."
"a23 a24 a25 a26 a27 a28 a29 a30 a31 a32"
". . . . . . . . . a33"
"a43 a42 a41 a40 a39 a38 a37 a36 a35 a34"
"a44 . . . . . . . . .""
"a45 a46 a47 a48 a49 a50 a51 a52 a53 a54";
*/

/*
". . . . . a54 a0 a1 . . . . ."
". . . . . a53 . a2 . . . . ."
". . . . . a52 . a3 . . . . ."
". . . . . a50 . a5 . . . . ."
". . . . . a49 . a6 . . . . ."
"a42 a43 a45 a46 a47 a48 . a7 a8 a9 a10 a12 a13"
"a41 . . . . . . . . . . . a55"
"a40 a39 a37 a36 a35 a34 . a20 a19 a18 a16 a15 a14"
". . . . . a33 . a21 . . . . ."
". . . . . a32 . a22 . . . . ."
". . . . . a30 . a24 . . . . ."
". . . . . a29 . a25 . . . . ."
". . . . . a28 a27 a26 . . . . .";

*/
