import '../Splash.css';
import React from 'react'
import DrPage from '../display/DrPage';
const InputResult = ({ textDelay, playerName, playerVal, isWinner, playerColor, valLabel }) => {

function shortName(name){
    if(name.length >= 8){
        return name.substring(0,7)+".."
    }
    return name
}

return (
        <div 
        className="inputResultText" 
        style={{color:playerColor, 
        animation: `reveal 1s ${textDelay}s forwards`,
        textShadow:`0 0 15px ${playerColor}`
        }}>
        <label style={{
        "width": "100%",
        "textAlign": "left"}}>
            {shortName(playerName) + (isWinner?" - DRINK":"")}</label>
        <label style={{"right":"0",
        "width": "100%",
        "textAlign": "right"}}>
            {playerVal===10000?'Early':playerVal+(valLabel)}</label>
        </div>
    )
}

export default InputResult;